import './information.css';
import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiInfo } from "react-icons/fi";
import { toast } from 'react-toastify';
import firebase from '../../services/firebaseConnection';


import JoditEditor from "jodit-react";
import {useRef, useState} from "react";

export default function Information(){
    const editor = useRef(null);
    const [content, setContent] = useState('');

    const config = {
        readonly:false,
        heigth:400
    }


    async function handleAdd(e){
        e.preventDefault();
        
        if(content !== ''){
            await firebase.firestore().collection('information')
            .add({
                content: content
            })
            .then(()=>{
                setContent('');

                toast.info('Informativo salvo com sucesso!');
            })  
                .catch((error)=>{
                    console.log(error);
                    toast.error('Erro ao preencher o campo');
                })
            
        }else{
            toast.error('Preencha o campo!');
        }
    }



    async function handleRegister(e){
        e.preventDefault();
    }


    const handleSave = () => {
        localStorage.setItem("informativo",content);
    }

    const loadDoc = () => {
        setContent(localStorage.getItem("document"));
    }

    const handleUpdate = (event) => {
        const editorContent = event.target.value
        setContent(editorContent)
    }

    return(
        <div>
            <Header/>
            <div className="content">
            <Title name="Informativos">
                <FiInfo size={25}/>
            </Title>


            <div className='jodit' onChange={handleRegister}>
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    onBlur={(newContent)=> setContent(newContent)}
                    onChange={(newContent)=> {}}
                />
            </div>

            <div className='btns'>
                <button className="infos" onClick={handleAdd}>Enviar</button>
            </div>

            </div>
        </div>
    )
}