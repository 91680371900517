import "./sacform.css";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";

import { doc, getDoc, updateDoc, collection } from "firebase/firestore";
import { Link } from "react-router-dom";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { db } from "../../services/firebaseConnection";

import { FiUserPlus } from "react-icons/fi";

import PhoneInput from 'react-phone-number-input';
import FlagsSelect from 'react-flags-select';
import 'react-phone-number-input/style.css';



export default function SacForm() {
    const [nomeCliente, setNomeCliente] = useState("");
    const [contrato, setContrato] = useState("");
    const [telefone, setTelefone] = useState("");
    const [dataSolicitacao, setDataSolicitacao] = useState("");
    const [motivo, setMotivo] = useState("");
    const [observacao, setObservacao] = useState("");
    const [solicitante, setSolicitante] = useState("");

    const [complemento, setComplemento] = useState("");


    const listRef = collection(db, "SacForm");

    const { user } = useContext(AuthContext);
    const match = useRouteMatch("/SacForm/:id");
    const id = match && match.params.id;


    const [loadCustomers, setLoadCustomers] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [customerSelected, setCustomerSelected] = useState(0);

    const [idCustomer, setIdCustumer] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState("BR");

    useEffect(() => {
        async function loadCustomers() {
            const querySnapshot = await getDoc(listRef)
                .get()
                .then((snapshot) => {
                    let lista = [];
                    snapshot.forEach((doc) => {
                        lista.push({
                            id: doc.id,
                            nomeCliente: doc.data().nomeCliente,
                            contrato: doc.data().contrato,
                            telefone: doc.data().telefone,
                            dataSolicitacao: doc.data().dataSolicitacao,
                            motivo: doc.data().motivo,
                            observacao: doc.data().observacao,
                            solicitante: doc.data().solicitante,
                        });
                    });

                    if (snapshot.doc.size === 0) {
                        console.log("Nenhum formulário encontrato");
                        setCustomers([{ id: "1", nome: "TESTE" }]);
                        setLoadCustomers(false);
                        return;
                    }

                    setCustomers(lista);
                    setLoadCustomers(false);

                    if (id) {
                        loadId(lista);
                    }
                })
                .catch((error) => {
                    console.log("ERROR!!!!", error);
                    setLoadCustomers(false);
                    setCustomers([{ id: "1", nome: "" }]);
                });
        }

        loadCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    async function loadId(lista) {
        const docRef = doc(db, "SacForm", id);
        await getDoc(docRef)
            .then((snapshot) => {
                setNomeCliente(snapshot.data().nomeCliente);
                setContrato(snapshot.data().contrato);
                setTelefone(snapshot.data().telefone);
                setDataSolicitacao(snapshot.data().dataSolicitacao);
                setMotivo(snapshot.data().motivo);
                setObservacao(snapshot.data().observacao);
                setSolicitante(snapshot.data().solicitante);

                let index = lista.findIndex(
                    (item) => item.id === snapshot.data().nomeId
                );
                setCustomerSelected(index);
                setIdCustumer(true);
            })
            .catch((error) => {
                // console.log(error)
                setIdCustumer(false);
            });
    }

    // console.log(filial,setor)

    function handleChangeSelect(e) {
        setMotivo(e.target.value);
    }

  

    async function handleRegister(e) {
        e.preventDefault();
        if (id) {
            const docRef = doc(db, "SacForm", id);
            await updateDoc(docRef, {
                nomeCliente: nomeCliente,
                contrato: contrato,
                telefone: telefone,
                dataSolicitacao: dataSolicitacao,
                motivo: motivo,
                observacao: observacao,
                solicitante: solicitante,
            })
                .then(() => {
                    toast.info("Formulário cadastrado com sucesso!");
                    setCustomerSelected(0);
                    setComplemento("");
                })
                .catch((error) => {
                    toast.error("Ops erro ao atualizar esse formulário!");
                    console.log(error);
                });

            return;
        }

        await firebase
            .firestore()
            .collection("SacForm")
            .add({
                create: new Date(),
                nomeCliente: nomeCliente,
                contrato: contrato,
                telefone: telefone,
                dataSolicitacao: dataSolicitacao,
                motivo: motivo,
                observacao: observacao,
                solicitante: solicitante,
                userId: user.uid,
            })
            .then(() => {
                toast.success("Formulário cadastrado com sucesso!");
            })
            .catch((err) => {
                toast.error("Erro ao registrar, tente mais tarde.");
                console.log(err);
            });
    }

   

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
    };

    useEffect(() => {
        loadId();
    }, [id]);


    return (
        <div>
            <Header />
            <div className="content">
                <Title name={id ? "Editando colaborador" : "Novo formulário"}>
                    <FiUserPlus size={25} />
                </Title>

                {/* <button type='submit' onClick={loadId}>Edit</button> */}
                <div className="formulario-sac">
                    <h2>Formulário para o SAC</h2>
                    <form className="form-new" onSubmit={handleRegister}>

                        <label>Nome do cliente:</label>
                        <input
                            type="text"
                            placeholder="Nome do cliente"
                            value={nomeCliente}
                            onChange={(e) => setNomeCliente(e.target.value)}
                        ></input>

                        <label>Número do contrato:</label>
                        <input
                            type="text"
                            placeholder="N° do contrato"
                            value={contrato}
                            onChange={(e) => setContrato(e.target.value)}
                        ></input>

                        <PhoneInput
                        style={{width:"300px"}}
                            placeholder="N° do telefone"
                            value={telefone}
                            onChange={(value) => setTelefone(value)}
                            defaultCountry={selectedCountry}
                            countries={['BR', 'US', 'GB', 'FR', 'DE', 'MX']} // Adicione os códigos de países que deseja suportar
                        />
                      

                        <label>Data de solicitação:</label>
                        <input
                            type="date"
                            value={dataSolicitacao}
                            onChange={(e) => setDataSolicitacao(e.target.value)}
                            dateFormat="dd/MM/yyyy"
                        ></input>

                        <label>Motivo da Solicitação:</label>
                        <select
                            className="filial-label"
                            onChange={handleChangeSelect}
                            value={motivo}
                        >
                            <option value="">Selecione o motivo</option>
                            <option value="Cancelamento">
                                Cancelamento
                            </option>
                            <option value="Duvidas contratuais">
                                Duvidas contratuais
                            </option>
                            <option value="Paralisação">
                                Paralisação
                            </option>
                            <option value="Taxa MRV">
                                Taxa MRV
                            </option>
                            <option value="Voucher/Renuncia - GRU/TRANSP/HOTEL">
                                Voucher/Renuncia - GRU/TRANSP/HOTEL
                            </option>
                            <option value="Reembolso - Familiar">
                                Reembolso - Familiar
                            </option>
                            <option value="Transporte/Hotel-SP">Transporte/Hotel-SP</option>
                            <option value="Outros">Outros</option>
                        </select>

                        <div className="obs-sac">
                            Observação:
                            <textarea value={observacao} onChange={(e) => setObservacao(e.target.value)} required />
                        </div>





                        <button className="register-btn-new-sac" type="submit" onClick={handleRegister}>
                            Registrar
                        </button>
                        <Link className="button-link-sac" to="/SacReport">
                            Voltar
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    );
}
