import { Switch, useRouteMatch } from 'react-router-dom';
import Route from './Route';

import SingIn from '../pages/SignIn';
import SingUp from '../pages/SingUp';

import Colaboradores from '../pages/Colaboradores';
import Profile from '../pages/Profile';
import Customers from '../pages/Customers';
import New from '../pages/New';
import Links from '../pages/Links'
import Information from '../pages/Information';
import Dashboard from '../pages/Dashboard';
import Company from '../pages/Company';
import Photo from '../pages/Photo';
import NewFeedback from '../pages/NewFeedback';
import Feedbacks from '../pages/Feedbacks';
import Users from '../pages/Users';
import Teste from '../pages/Teste';

import Tickets from '../pages/Tickets';
import ResetPassword from "../pages/ResetPassword";
import BaseLeads from "../pages/BaseLeads";
import NewBaseLeads from "../pages/NewBaseLeads";

import SacForm from '../pages/SacForm';
import SacReport from '../pages/SacReport';







import { useContext } from "react";
import { AuthContext } from '../contexts/auth';


export default function Rout() {

    const { user } = useContext(AuthContext);
    const type = user && user.type;
    const match = useRouteMatch("/newbaseleads/:id");
    const id = match && match.params.id;



    if (type === "Administrativo" || type === "Cadastro" || type === "Administrativo" || type === "Financeiro"
        || type === "Jurídico" || type === "Marketing" || type === "SAC"
        || type === "Segurança" || type === "Transmissão" || type === "Treinamento" ||
        type === "Turismo") {
        return (
            <Switch>
                <Route exact path="/" component={SingIn} />
                <Route exact path="/dashboard" component={Dashboard} isPrivate />
                <Route exact path="/colaboradores" component={Colaboradores} isPrivate />
                <Route exact path="/profile" component={Profile} isPrivate />
                <Route exact path="/customers" component={Customers} isPrivate />
                <Route exact path="/new" component={New} isPrivate />
                <Route exact path="/new/:id" component={New} isPrivate />
                <Route exact path="/links" component={Links} isPrivate />
                <Route exatc path="/information" component={Information} isPrivate />
                <Route exatc path="/Company" component={Company} isPrivate />
                <Route exatc path="/ResetPassword" component={ResetPassword} />
                <Route exatc path="/SacForm" component={SacForm} isPrivate />
                <Route exatc path="/SacForm/:id" component={SacForm} isPrivate />
                <Route exatc path="/SacReport" component={SacReport} isPrivate />
            </Switch>
        )
    }


    else if (type === "Comercial") {
        return (
            <Switch>
                <Route exact path="/" component={SingIn} />
                <Route exact path="/tickets" component={Tickets} isPrivate />
                <Route exact path="/teste" component={Teste} isPrivate />
                <Route exatc path="/ResetPassword" component={ResetPassword} />
                <Route exatc path="/newbaseleads" component={NewBaseLeads} isPrivate />
                <Route exatc path="/newbaseleads/:id" component={NewBaseLeads} isPrivate />
                <Route exatc path="/BaseLeads" component={BaseLeads} isPrivate />
                <Route exatc path="/SacForm" component={SacForm} isPrivate />
            </Switch>
        )
    }

    else if (type === "TI" || type === "Diretoria") {
        return (
            <Switch>
                <Route exact path="/" component={SingIn} />
                <Route exact path="/register" component={SingUp} isPrivate />
                <Route exact path="/dashboard" component={Dashboard} isPrivate />
                <Route exact path="/colaboradores" component={Colaboradores} isPrivate />
                <Route exact path="/feedbacks" component={Feedbacks} isPrivate />
                <Route exact path="/profile" component={Profile} isPrivate />
                <Route exact path="/customers" component={Customers} isPrivate />
                <Route exact path="/new" component={New} isPrivate />
                <Route exact path="/new/:id" component={New} isPrivate />
                <Route exact path="/links" component={Links} isPrivate />
                <Route exatc path="/information" component={Information} isPrivate />
                <Route exatc path="/Newfeedback" component={NewFeedback} isPrivate />
                <Route exatc path="/Company" component={Company} isPrivate />
                <Route exatc path="/photo" component={Photo} isPrivate />
                <Route exatc path="/users" component={Users} isPrivate />
                <Route exact path="/tickets" component={Tickets} isPrivate />
                <Route exact path="/teste" component={Teste} isPrivate />
                <Route exatc path="/ResetPassword" component={ResetPassword} />
                <Route exatc path="/newbaseleads" component={NewBaseLeads} isPrivate />
                <Route exatc path="/newbaseleads/:id" component={NewBaseLeads} isPrivate />
                <Route exatc path="/BaseLeads" component={BaseLeads} isPrivate />
                <Route exatc path="/SacForm" component={SacForm} isPrivate />
                <Route exatc path="/SacForm/:id" component={SacForm} isPrivate />
                <Route exatc path="/SacReport" component={SacReport} isPrivate />
            </Switch>
        )
    }


    else if (type === "Gerenciamento de Leads") {
        return (
            <Switch>
                <Route exact path="/" component={SingIn} />
                <Route exact path="/dashboard" component={Dashboard} isPrivate />
                <Route exact path="/colaboradores" component={Colaboradores} isPrivate />
                <Route exact path="/feedbacks" component={Feedbacks} isPrivate />
                <Route exact path="/profile" component={Profile} isPrivate />
                <Route exact path="/customers" component={Customers} isPrivate />
                <Route exact path="/new" component={New} isPrivate />
                <Route exact path="/new/:id" component={New} isPrivate />
                <Route exact path="/links" component={Links} isPrivate />
                <Route exatc path="/Newfeedback" component={NewFeedback} isPrivate />
                <Route exatc path="/Company" component={Company} isPrivate />
                <Route exatc path="/ResetPassword" component={ResetPassword} />
                <Route exatc path="/newbaseleads" component={NewBaseLeads} isPrivate />
                <Route exatc path="/newbaseleads/:id" component={NewBaseLeads} isPrivate />
                <Route exatc path="/BaseLeads" component={BaseLeads} isPrivate />
                <Route exatc path="/SacForm" component={SacForm} isPrivate />
            </Switch>
        )
    }

    else if (type === "Departamento Pessoal") {
        return (
            <Switch>
                <Route exact path="/" component={SingIn} />
                <Route exact path="/dashboard" component={Dashboard} isPrivate />
                <Route exact path="/colaboradores" component={Colaboradores} isPrivate />
                <Route exact path="/profile" component={Profile} isPrivate />
                <Route exact path="/customers" component={Customers} isPrivate />
                <Route exact path="/new" component={New} isPrivate />
                <Route exact path="/new/:id" component={New} isPrivate />
                <Route exact path="/links" component={Links} isPrivate />
                <Route exatc path="/information" component={Information} isPrivate />
                <Route exatc path="/Newfeedback" component={NewFeedback} isPrivate />
                <Route exatc path="/Company" component={Company} isPrivate />
                <Route exatc path="/ResetPassword" component={ResetPassword} />
                <Route exatc path="/SacForm" component={SacForm} isPrivate />
            </Switch>
        )
    } else {

        return (
            <Switch>
                <Route exact path="/" component={SingIn} />
                <Route exact path="/register" component={SingUp} isPrivate />

                <Route exact path="/dashboard" component={Dashboard} isPrivate />
                <Route exact path="/colaboradores" component={Colaboradores} isPrivate />
                <Route exact path="/feedbacks" component={Feedbacks} isPrivate />
                <Route exact path="/profile" component={Profile} isPrivate />
                <Route exact path="/customers" component={Customers} isPrivate />
                <Route exact path="/new" component={New} isPrivate />
                <Route exact path="/new/:id" component={New} isPrivate />
                <Route exact path="/links" component={Links} isPrivate />
                <Route exatc path="/information" component={Information} isPrivate />
                <Route exatc path="/Newfeedback" component={NewFeedback} isPrivate />
                <Route exatc path="/Company" component={Company} isPrivate />
                <Route exatc path="/photo" component={Photo} isPrivate />
                <Route exatc path="/users" component={Users} isPrivate />
                <Route exact path="/tickets" component={Tickets} isPrivate />
                <Route exact path="/teste" component={Teste} isPrivate />
                <Route exatc path="/ResetPassword" component={ResetPassword} />
                <Route exatc path="/newbaseleads" component={NewBaseLeads} isPrivate />
                <Route exatc path="/newbaseleads/:id" component={NewBaseLeads} isPrivate />
                <Route exatc path="/BaseLeads" component={BaseLeads} isPrivate />
                <Route exatc path="/SacForm" component={SacForm} isPrivate />
                <Route exatc path="/SacForm/:id" component={SacForm} isPrivate />
                <Route exatc path="/SacReport" component={SacReport} isPrivate />
            </Switch>
        )
    }
}
