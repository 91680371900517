import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';



let firebaseConfig = {
    apiKey: "AIzaSyCi-To7BZh26urDlCn2oNaM01VWOUvddGk",
    authDomain: "sistema-8c680.firebaseapp.com",
    projectId: "sistema-8c680",
    storageBucket: "sistema-8c680.appspot.com",
    messagingSenderId: "15274377625",
    appId: "1:15274377625:web:84b0301c1d8b15079bd308",
    measurementId: "G-GXWNWP7J9Y"
  };
  
  
  
  if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
  }

  const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export {auth, db, storage};

  export default firebase;