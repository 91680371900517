import './modal.css';
import { FiX } from 'react-icons/fi';
import moment from 'moment';


// IMPORT DAS FOTOS DOS FUNCIONÁRIOS
import junior from '../../assets/photos/junior.jpg';
import lucasassis from '../../assets/photos/Lucas_Assis.jpeg';
import fabriciogouveia from '../../assets/photos/fabricio.jfif';
import luanpires from '../../assets/photos/Luan_Pires.jpg';
import gabrielneves from '../../assets/photos/Gabriel_Neves.jpg';
import soraya from '../../assets/photos/Soraya.jpg';

//




export default function Modal({conteudo,close}){
    return(
        <div className='modal-exib'>
            <div className='container-modal'>
                <button className='close' onClick={close}>
                    <FiX size={23} color="#FFF"/>
                    
                </button>
                
                <div className="detalhes-colab">
                    <h2>Detalhes do colaborador</h2>

                    <div className='row'>
                        <span>
                            Nome: <a>{conteudo.nome}</a>
                        </span>
                    </div>

                    <div className='row'>
                        <span>
                            Email: <a>{conteudo.email}</a>
                        </span>
                    </div>

                    <div className='row'>
                        <span>
                            Ramal: <a>{conteudo.ramal}</a>
                        </span>
                    </div>

                    <div className='row'>
                        <span>
                            Setor: <a>{conteudo.setor}</a>
                        </span>
                    </div>

                    <div className='row'>
                        <span>
                            Função: <a>{conteudo.funcao}</a>
                        </span>
                    </div>

                    <div className='row'>
                        <span>
                            Filial: <a>{conteudo.filial}</a>
                        </span>
                    </div>

                    <div className='row' style={{display:"none"}}>
                        <span>
                            Data de Nascimento: <a>{moment(conteudo.dataNascimento).format("DD/MM/YYYY")}</a>
                        </span>
                    </div>
                    

                    <div className='mod-modal'>
                {conteudo.nome === "Geraldo Glicério da Silva Júnior" ? (
                                <img src={junior} alt="Foto de perfil" width={250} height={250}/>
                            ) : (
                                <p></p>
                            )}

                            {conteudo.nome === "Lucas Assis Alves" ? (
                                <img src={lucasassis} alt="Foto de perfil" width={250} height={250}/>
                            ) : (
                                <p></p>
                            )}

                              {conteudo.nome === "Fabrício Gouvéia dos Santos" ? (
                                <img src={fabriciogouveia} alt="Foto de perfil" width={250} height={250}/>
                            ) : (
                                <p></p>
                            )}
                            {conteudo.nome === "Luan Marçal Pires" ? (
                                <img src={luanpires} alt="Foto de perfil" width={250} height={250}/>
                            ) : (
                                <p></p>
                            )}
                            {conteudo.nome === "Gabriel Neves Brasil" ? (
                                <img src={gabrielneves} alt="Foto de perfil" width={250} height={250}/>
                            ) : (
                                <p></p>
                            )}
                            {conteudo.nome === "Soraya Adryana da Fonseca" ? (
                                <img src={soraya} alt="Foto de perfil" width={250} height={250}/>
                            ) : (
                                <p></p>
                            )}

                         
                
                </div>
                </div>
                
            </div>
        </div>
    )
}