import './cupom03.css';
import { useContext,useRef } from 'react';
import { AuthContext } from '../../contexts/auth';
import { FiX } from 'react-icons/fi';

import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

import tickets01 from "../../assets/ticket03-3.png";

export default function Cupom03({conteudo,close}){
    const { user} = useContext(AuthContext);
    const cupom = user && user.cupom;


  const modalRef = useRef(null);

  const handleReload = () => {
    window.location.reload();
  };


  const handlePrint = () => {
    html2canvas(modalRef.current).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, 'modal.png');
        handleReload();
      });
    });
  };

  // Resto do código do componente
  
 

    return(
        <div  className='modal-cupom03'>
            <button className='imprimir2' onClick={handlePrint}>Download</button>
            <img src={tickets01} alt="Foto de perfil" width={300} height={400}/>
            <div ref={modalRef} className='container-cupom03' onClick={handlePrint} >
            
           
                <button className='close' onClick={close} style={{display:"none"}}>
                    <FiX size={23} color="#FFF"/>
                    
                </button>
                
                <div className="detalhes-cupom03">
                   
                    <div className='row'>
                        <span>
                            {cupom}
                        </span>
                    </div>
                    
            
                  
                    
                </div>
                
            </div>
        </div>
    )
}