import "./feedbacks.css";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import moment from 'moment';

import { toast } from "react-toastify";
import Header from "../../components/Header";
import Title from "../../components/Title";
import {
  FiList,
  FiPlus,
  FiClipboard,
  FiSearch,
  FiTrash2,
  FiUsers,
} from "react-icons/fi";

import { SiMicrosoftexcel } from "react-icons/si"
import { CgPlayListSearch } from "react-icons/cg";
import { Link } from "react-router-dom";

import firebase from "../../services/firebaseConnection";
import "firebase/firestore";

import * as XLSX from 'xlsx';

import ModalFeedback from "../../components/ModalFeedback";


const listRef = firebase.firestore().collection("feedbacks");

export default function Feedbacks() {
  const [func, setFunc] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();
  const [todosFeedbacks, setTodosFeedbacks] = useState([]);

  const [filialSearch, setFilialSearch] = useState("");
  const [dateSearch, setDateSearch] = useState('');

  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  // console.log(search);
  const searchLowerCase = search.toLowerCase();
  const db = firebase.firestore();

  function searchConsultor() {
    const searchLowerCase = search.toLowerCase();
    const userSearch = func.filter(
      (consultor) =>
        consultor.consultor.toLowerCase().includes(searchLowerCase) ||
        consultor.nomeCliente.toLowerCase().includes(searchLowerCase) ||
        consultor.responsavel.toLowerCase().includes(searchLowerCase) ||
        consultor.dataAnalise.toLowerCase().includes(searchLowerCase) ||
        consultor.numeroCliente.toLowerCase().includes(searchLowerCase) ||
        consultor.filial.toLowerCase().includes(searchLowerCase)

    );
    setFunc(userSearch);
  }

  function searchDate() {
    const searchLowerCase = search.toLowerCase();
    const userSearch = listRef.filter(
      (consultor) =>
        consultor.dataAnalise.toLowerCase().includes(searchLowerCase)
    );
    setFunc(userSearch);
  }

  // function searchFiliais() {
  //   const searchLowerCase = filialSearch.toLowerCase();
  //   const filiaisSearch = func.filter(
  //     (consultor) =>
  //     consultor.filial.toLowerCase().includes(searchLowerCase) 
  //   );
  //   setFunc(filiaisSearch);
  // }
  function deleteFeedback(id) {
    const confirmDelete = window.confirm("Deseja mesmo excluir o Feedback?");
    if (confirmDelete) {
      const userDelete = func.filter((feedback) => feedback.id !== id);
      setFunc(userDelete);

      db.collection("feedbacks")
        .doc(id)
        .delete()
        .then(() => {
          toast.success("Feedback deletado com sucesso!");
        })
        .catch(() => {
          toast.error("Erro ao deletar Feedback");
        });
    } else {
      return;
    }
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      searchConsultor();
    }
  }



  const exportData = async () => {
    const worksheet = XLSX.utils.json_to_sheet(func); // converte os dados em uma planilha Excel
    const workbook = XLSX.utils.book_new(); // cria um novo livro de Excel
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados'); // adiciona a planilha de dados ao livro de Excel
    XLSX.writeFile(workbook, 'feedbacks.xlsx'); // baixa o arquivo Excel com os dados

  };



  useEffect(() => {
    async function loadFeedback() {
      await listRef
        .limit()
        .get()
        .then((snapshot) => {
          updateState(snapshot);
        })
        .catch((err) => {
          console.log("Erro ao buscar", err);
          setLoadingMore(false);
        });

      setLoading(false);
    }

    loadFeedback();

    return () => { };
  }, []);

  useEffect(() => {
    if (search === "") {
      setFunc(todosFeedbacks);
    }
  }, [search]);

  async function updateState(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];

      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          responsavel: doc.data().responsavel,
          consultor: doc.data().consultor,
          filial: doc.data().filial,
          ramal: doc.data().ramal,
          dataAnalise: doc.data().dataAnalise,
          nomeCliente: doc.data().nomeCliente,
          numeroCliente: doc.data().numeroCliente,
          observacoes: doc.data().observacoes,
          avaliacao: doc.data().avaliacao
        });
      });

      const lastDoc = snapshot.docs[snapshot.docs.length - 1]; // pegando o ultimo documento buscado

      setFunc(lista);
      setTodosFeedbacks(lista);
      setLastDocs(lastDocs);
    } else {
      setIsEmpty(true);
    }

    setLoadingMore(false);
  }

  async function handleMore() {
    setLoadingMore(true);
    await listRef
      .limit()
      .get()
      .then((snapshot) => {
        updateState(snapshot);
      });
  }

  function togglePostModal(item) {
    setShowPostModal(!showPostModal); // Trocando de true para false conforme recebe o valor
    setDetail(item);
  }

  const ordenarCrescente = (index) => {
    const ordenado = [...func].sort((a, b) => {
      if (a[index] < b[index]) {

        return -1;

      }
      if (a[index] > b[index]) {
        return 1;
      }
      return 0;
    });
    setFunc(ordenado);
  }

  const ordenarDecrescente = (index) => {
    const ordenado = [...func].sort((a, b) => {
      if (a[index] > b[index]) {

        return -1;

      }
      if (a[index] < b[index]) {
        return 1;
      }
      return 0;
    });
    setFunc(ordenado);
  }

  function handlePageClick(data) {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  }

  if (loading) {
    return (
      <div>
        <Header />
        <div className="content-colab">
          <Title name="Lista">
            <FiList size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando feedbacks....</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Lista de Feedbacks">
          <FiUsers size={20} />
        </Title>
        <div className="divSearch">
          <input
            className="inputFeed"
            type="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            placeholder="Buscar"

          />
          <input
            className="input-date"
            type="date"
            value={dateSearch}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
          />

          {/* <select
              className="filial-selected"
              onChange={(e) => setFilialSearch(e.target.value)}
              value={filialSearch}
            >
              <option value="">Selecione a sua Filial</option>
              <option value="Matriz">Matriz</option>
              <option value="Filial 01 (Cidade Nobre - Ipatinga-MG)">
                Filial 01 (Cidade Nobre - Ipatinga-MG)
              </option>
              <option value="Filial 02 (Centro - Ipatinga-MG)">
                Filial 02 (Centro - Ipatinga-MG)
              </option>
              <option value="Filial 03 (Shopping Vale do Aço - Ipatinga-MG)">
                Filial 03 (Shopping Vale do Aço - Ipatinga-MG)
              </option>
              <option value="Filial 04 (Ponteio Lar Shopping - BH)">
                Filial 04 (Ponteio Lar Shopping - BH)
              </option>
              <option value="Filial 05 (Caratinga-MG)">
                Filial 05 (Caratinga-MG){" "}
              </option>
              <option value="Filial 06 (Iguaçu - Ipatinga-MG)">
                Filial 06 (Iguaçu - Ipatinga-MG)
              </option>
              <option value="Manhuaçu">Manhuaçu</option>
            </select> */}


          <button
            className="btn-searchFeed"
            onClick={searchConsultor}
            onKeyDown={(event) => event.key === "Enter" && searchConsultor}
          >
            <FiSearch size={12} color="#FFF" />
            Buscar
          </button>

        </div>

        {func.length === 0 ? (
          <div className="container dashboard">
            <span>Nenhum registro de feedback ...</span>


          </div>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th scope="col" onClick={() => ordenarCrescente("consultor")}>Consultor</th>
                  <th scope="col" onClick={() => ordenarCrescente("nomeCliente")}>
                    Nome Cliente
                  </th>
                  <th scope="col" onClick={() => ordenarCrescente("numeroCliente")} >
                    Contato
                  </th>
                  <th scope="col" onClick={() => ordenarCrescente("avaliacao")}>
                    Avaliação
                  </th>
                  <th scope="col" onClick={() => ordenarCrescente("filial")}>Filial</th>
                  <th scope="col" style={{ display: "none" }}>
                    Função
                  </th>
                  <th scope="col" onClick={() => ordenarCrescente("dataAnalise")}>
                    Data Analise
                  </th>
                  <th scope="col" onClick={() => ordenarCrescente("responsavel")}>
                    Responsável
                  </th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <tbody>
                {func
                  .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="Consultor" onClick={() => ordenarDecrescente("consultor")}>{item.consultor}</td>
                        <td data-label="Nome Cliente" onClick={() => ordenarDecrescente("nomeCliente")}>
                          {item.nomeCliente}
                        </td>
                        <td data-label="Numero Cliente" onClick={() => ordenarDecrescente("numeroCliente")}>
                          {item.numeroCliente}
                        </td>
                        <td className="avaliacao" data-label="Avaliação" font="bold" style={{
                          fontWeight: 'bold',
                          color: item.avaliacao === 'A' ? '#00b34c' :
                            item.avaliacao === 'B' ? '#ffd41f' :
                              item.avaliacao === 'C' ? '#FF0004' : '#f8f8f8'
                        }} onClick={() => ordenarDecrescente("avaliacao")}>
                          {item.avaliacao}
                        </td>
                        <td data-label="Filial" onClick={() => ordenarDecrescente("filial")}>
                          {item.filial}
                        </td>
                        <td data-label="Data Analise" onClick={() => ordenarDecrescente("dataAnalise")}>
                          {moment(item.dataAnalise).format("DD/MM/YYYY")}
                        </td>
                        <td data-label="Data Analise" onClick={() => ordenarDecrescente("responsavel")}>
                          {item.responsavel}
                        </td>
                        <td data-label="#">



                          <button
                            className="action"
                            style={{ backgroundColor: "#0B0B34" }}
                            onClick={() => togglePostModal(item)}
                          >
                            <FiClipboard color="#FFF" size={17} />
                          </button>

                          {/* <button
                          className="action"
                          style={{ backgroundColor: "#a4c639" }}
                          onClick= {""}
                        >
                          <FiPhone color="#FFF" size={17} />
                        </button> */}

                          {/* <Link
                          className="action"
                          style={{ backgroundColor: "#f6a935" }}
                          to={`/NewFeedback/${item.id}`}
                        >
                          <FiEdit2 color="#FFF" size={17} />
                        </Link> */}


                          <button
                            className="action"
                            style={{ backgroundColor: "#FF0004" }}
                            onClick={() => deleteFeedback(item.id)}
                          >
                            <FiTrash2 color="#FFF" size={17} />
                          </button>


                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              pageCount={Math.ceil(func.length / itemsPerPage)}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />


            <div className="more">
              {loadingMore && (
                <h3 style={{ textAlign: "center", marginTop: 15 }}>
                  Buscando Feedbacks...
                </h3>
              )}
              {!loadingMore && !isEmpty && (
                <button className="btn-more" onClick={handleMore}>
                  <CgPlayListSearch size={17} color="#FFF" />
                  Buscar Mais
                </button>

              )}


              <div className="novoColaborador">
                <Link to="/NewFeedback" className="new">
                  <FiPlus size={15} color="#FFF" />
                  Novo FeedBack
                </Link>

                {/*<div className="exportExcell">
                  <button className="btn-export" onClick={exportData}><SiMicrosoftexcel size={15} />Exportar</button>
              </div>*/}

              </div>

            </div>
          </>
        )}
      </div>

      {showPostModal && <ModalFeedback conteudo={detail} close={togglePostModal} />}


    </div>
  );
}
