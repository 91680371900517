import "./new.css";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";

import { doc, getDoc, updateDoc, collection } from "firebase/firestore";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

import { db } from "../../services/firebaseConnection";

import { FiUserPlus } from "react-icons/fi";

export default function New() {
  const [filial, setFilial] = useState("");
  const [setor, setSetor] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [ramal, setRamal] = useState("");
  const [funcao, setFuncao] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");

  const [complemento, setComplemento] = useState("");

  const listRef = collection(db, "colaboradores");

  const { user } = useContext(AuthContext);
  const { id } = useParams();

  const [loadCustomers, setLoadCustomers] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState(0);

  const [idCustomer, setIdCustumer] = useState(false);

  useEffect(() => {
    async function loadCustomers() {
      const querySnapshot = await getDoc(listRef)
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              nome: doc.data().nome,
              email: doc.data().email,
              filial: doc.data().filial,
              setor: doc.data().setor,
              ramal: doc.data().email,
              funcao: doc.data().funcao,
              dataNascimento: doc.data().dataNascimento,
            });
          });

          if (snapshot.doc.size === 0) {
            console.log("Nenhum colaborador encontrado");
            setCustomers([{ id: "1", nome: "TESTE" }]);
            setLoadCustomers(false);
            return;
          }

          setCustomers(lista);
          setLoadCustomers(false);

          if (id) {
            loadId(lista);
          }
        })
        .catch((error) => {
          console.log("ERROR!!!!", error);
          setLoadCustomers(false);
          setCustomers([{ id: "1", nome: "" }]);
        });
    }

    loadCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function loadId(lista) {
    const docRef = doc(db, "colaboradores", id);
    await getDoc(docRef)
      .then((snapshot) => {
        setNome(snapshot.data().nome);
        setEmail(snapshot.data().email);
        setFilial(snapshot.data().filial);
        setSetor(snapshot.data().setor);
        setRamal(snapshot.data().ramal);
        setFuncao(snapshot.data().funcao);
        setDataNascimento(snapshot.data().dataNascimento);

        let index = lista.findIndex(
          (item) => item.id === snapshot.data().nomeId
        );
        setCustomerSelected(index);
        setIdCustumer(true);
      })
      .catch((error) => {
        // console.log(error)
        setIdCustumer(false);
      });
  }

  // console.log(filial,setor)

  function handleChangeSelect(e) {
    setFilial(e.target.value);
  }

  function handleOptionChange(e) {
    setSetor(e.target.value);
  }

  async function handleRegister(e) {
    e.preventDefault();
    if (id) {
      const docRef = doc(db, "colaboradores", id);
      await updateDoc(docRef, {
        filial: filial,
        email:email,
        setor: setor,
        nome: nome,
        ramal: ramal,
        funcao: funcao,
        dataNascimento: dataNascimento,
      })
        .then(() => {
          toast.info("Colaborador atualizado com sucesso!");
          setCustomerSelected(0);
          setComplemento("");
        })
        .catch((error) => {
          toast.error("Ops erro ao atualizar esse colaborador!");
          console.log(error);
        });

      return;
    }

    await firebase
      .firestore()
      .collection("colaboradores")
      .add({
        create: new Date(),
        nome: nome,
        email: email,
        filial: filial,
        setor: setor,
        ramal: ramal,
        funcao: funcao,
        dataNascimento: dataNascimento,
        userId: user.uid,
      })
      .then(() => {
        toast.success("Colaborador cadastrado com sucesso!");
      })
      .catch((err) => {
        toast.error("Erro ao registrar, tente mais tarde.");
        console.log(err);
      });
  }

  // console.log(loadId)
  useEffect(() => {
    loadId();
  }, [id]);

  return (
    <div>
      <Header />
      <div className="content">
        <Title name={id ? "Editando colaborador" : "Novo colaborador"}>
          <FiUserPlus size={25} />
        </Title>

        {/* <button type='submit' onClick={loadId}>Edit</button> */}
        <div className="formulario">
          <form className="form-new" onSubmit={handleRegister}> 
            <label>Nome:</label>
            <input
              type="text"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            ></input>

            <label>Email:</label>
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>

            <label>Filial:</label>
            <select
              className="filial-label"
              onChange={handleChangeSelect}
              value={filial}
            >
              <option value="">Selecione a sua Filial</option>
              <option value="Matriz">Matriz</option>
              <option value="Filial 01 (Cidade Nobre - Ipatinga-MG)">
                Filial 01 (Cidade Nobre - Ipatinga-MG)
              </option>
              <option value="Filial 02 (Centro - Ipatinga-MG)">
                Filial 02 (Centro - Ipatinga-MG)
              </option>
              <option value="Filial 03 (Shopping Vale do Aço - Ipatinga-MG)">
                Filial 03 (Shopping Vale do Aço - Ipatinga-MG)
              </option>
              <option value="Filial 04 (Ponteio Lar Shopping - BH)">
                Filial 04 (Ponteio Lar Shopping - BH)
              </option>
              <option value="Filial 05 (Caratinga-MG)">
                Filial 05 (Caratinga-MG){" "}
              </option>
              <option value="Filial 06 (Iguaçu - Ipatinga-MG)">
                Filial 06 (Iguaçu - Ipatinga-MG)
              </option>
              <option value="Manhuaçu">Manhuaçu</option>
            </select>

            <label>Setor:</label>
            <select
              className="setor"
              onChange={handleOptionChange}
              value={setor}
            >
              <option value="">Selecione o seu Setor</option>
              <option value="Administrativo">Administrativo</option>
              <option value="Cadastro">Cadastro</option>
              <option value="Comercial">Comercial</option>
              <option value="Diretoria">Diretoria</option>
              <option value="Departamento Pessoal">Departamento Pessoal</option>
              <option value="Financeiro">Financeiro</option>
              <option value="Gerenciamento de Leads">
                Gerenciamento de Leads
              </option>
              <option value="Jurídico">Jurídico</option>
              <option value="Marketing">Marketing</option>
              <option value="SAC">SAC</option>
              <option value="Segurança">Segurança</option>
              <option value="TI">TI</option>
              <option value="Tranmissão">Tranmissão</option>
              <option value="Treinamento">Treinamento</option>
              <option value="Turismo">Turismo</option>
            </select>

            <label>Ramal:</label>
            <input
              type="text"
              placeholder="Ramal ***-****"
              value={ramal}
              onChange={(e) => setRamal(e.target.value)}
            ></input>

            <label>Função:</label>
            <input
              type="text"
              placeholder="Função no Setor"
              value={funcao}
              onChange={(e) => setFuncao(e.target.value)}
            ></input>

            <label>Data de Nascimento:</label>
              <input
              type="date"
              value={dataNascimento}
              onChange={(e) => setDataNascimento(e.target.value)}
              dateFormat="dd/MM/yyyy"
              ></input>

            <button className="register-btn-new" type="submit" onClick={loadId}>
              Registrar
            </button>
            <Link className="button-link" to="/colaboradores">
            Voltar
          </Link>
          </form>
        </div>
      </div>
    </div>
  );
}
