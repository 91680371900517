import { useContext, useState } from "react";
import "./header.css";
import { AuthContext } from "../../contexts/auth";
import avatar from "../../assets/avatar.png";
import moment from 'moment';

import { Link } from "react-router-dom";
import {
  FiHome,
  FiUsers,
  FiMonitor,
  FiShare2,
  FiLogOut,
  FiInfo,
  FiUser,
  FiUserPlus

} from "react-icons/fi";
import { BiBuilding, BiDetail } from "react-icons/bi";
import { HiOutlineTicket } from 'react-icons/hi';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai';
import { GoThumbsup, GoChecklist } from 'react-icons/go';

export default function Header() {
  const { user, signOut } = useContext(AuthContext);

  const [isOpenSac, setIsOpenSac] = useState(false);
  const [isOpenComercial, setIsOpenComercial] = useState(false);

  const [nome, setNome] = useState("Lucas");

  const type = user && user.type;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdownComercial = () => {
    setIsOpenComercial(!isOpenComercial);
    setIsOpenSac(window.closed);
  };

  const toggleDropdownSAC = () => {
    setIsOpenSac(!isOpenSac);
    setIsOpenComercial(window.closed)
  };

  if (type === "Administrativo") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>


          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>


          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>
          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>


          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );


  } else if (type === "Cadastro") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>


          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>

          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>



          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "Comercial") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/tickets">
            <HiOutlineTicket color="#FFF" size={15} />
            Tickets
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>



          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "Diretoria") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Dashboard">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/BaseLeads">
            <FiUserPlus color="#FFF" size={15} />
            Base de Leads
          </Link>


          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>


          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>


          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "Departamento Pessoal") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>


          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>

          {/*<Link className="button" to="/information">
          <FiInfo color="#FFF" size={15} />
          Informações
        </Link>*/}

          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>



          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "Financeiro") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>


          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>



          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "Gestão comercial") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Dashboard">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>

          <Link className="button" to="/BaseLeads">
            <FiUserPlus color="#FFF" size={15} />
            Base de Leads
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownComercial}>
              <FiMonitor color="#FFF" size={15} />
              Monitoramento
            </div>
            {isOpenComercial && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/feedbacks">Feedback</Link>
                </li>
                <li>
                  <Link to="/relatorios">Relatório</Link>
                </li>
              </ul>
            )}
          </div>
          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>



          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );
  }
  else if (type === "Gerenciamento de Leads") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Dashboard">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>

          <Link className="button" to="/BaseLeads">
            <FiUserPlus color="#FFF" size={15} />
            Base de Leads
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownComercial}>
              <FiMonitor color="#FFF" size={15} />
              Monitoramento
            </div>
            {isOpenComercial && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/feedbacks">Feedback</Link>
                </li>
                <li>
                  <Link to="/relatorios">Relatório</Link>
                </li>
              </ul>
            )}
          </div>
          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>


          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "Jurídico") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>


          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                  <Link to="/SacReport"><GoChecklist size={15} />Relatorios</Link>
                </li>
              </ul>
            )}
          </div>


          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "Marketing") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>


          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>



          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "SAC") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>

          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                  <Link to="/SacReport"><GoChecklist size={15} />Relatorios</Link>
                </li>
              </ul>
            )}
          </div>


          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "Segurança") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>

          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/users">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/formulario-juridico">Formulário SAC</Link>
                </li>
                <li>
                  <Link to="/relatorios-sac">Relatorios</Link>
                </li>
              </ul>
            )}
          </div>

          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "TI") {
    return (
      <div className="sidebar">
        <div className="menu-icon" onClick={toggleMenu}>
        <div className={`menu-icon-bars ${isMenuOpen ? "open" : ""}`} />
        <div className={`menu-icon-bars ${isMenuOpen ? "open" : ""}`} />
        <div className={`menu-icon-bars ${isMenuOpen ? "open" : ""}`} />
      </div>
      
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        {isMenuOpen && (
          <div className="mobile-menu">
          <Link className="button" to="/dashboard">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/BaseLeads">
            <FiUserPlus color="#FFF" size={15} />
            Base de Leads
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>
        </div>
        )}
        <div className="buttons">
          <Link className="button" to="/dashboard">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/BaseLeads">
            <FiUserPlus color="#FFF" size={15} />
            Base de Leads
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownComercial}>
              <FiMonitor color="#FFF" size={15} />
              Monitoramento
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenComercial && (
              <ul className="dropdown-menu">
                <li>

                  <Link to="/feedbacks"><GoThumbsup size={15} />Feedback</Link>
                </li>
                <li>
                  <Link to="/relatorios"><GoChecklist size={15} />Relatório</Link>
                </li>
              </ul>
            )}
          </div>
          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>

          {/*<Link className="button" to="/information">
          <FiInfo color="#FFF" size={15} />
          Informações
        </Link>*/}

          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <Link className="button" to="/users">
            <FiUsers color="#FFF" size={15} />
            Usuários
          </Link>

          <Link className="button" to="/tickets">
            <HiOutlineTicket color="#FFF" size={15} />
            Tickets
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                  <Link to="/SacReport"><GoChecklist size={15} />Relatorios</Link>
                </li>
              </ul>
            )}
          </div>

          {/*<Link className="button" to="/formulario-juridico">
            <HiOutlineTicket color="#FFF" size={15} />
            SAC
          </Link>

          <Link className="button" to="/formulario-juridico-table">
            <HiOutlineTicket color="#FFF" size={15} />
            Relatório chamados SAC
            </Link> */}






          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "Transmissão") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>


          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>



          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  } else if (type === "Treinamento") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>


          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>



          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  }
  else if (type === "Turismo") {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>

          <Link className="button" to="/colaboradores">
            <FiUsers color="#FFF" size={15} />
            Colaboradores
          </Link>


          {/* <Link to="/customers">
                <FiUser color='#FFF' size={24}/>
                Usuários
            </Link> */}
          {/* <Link className= "button" to="/profile">
                <FiSettings color='#FFF' size={15}/>
                Configurações
            </Link> */}

          <Link className="button" to="/links">
            <FiShare2 color="#FFF" size={15} />
            Links Utéis
          </Link>



          {/* <Link className="button" to="/register">
          <FiUser color="#FFF" size={15} />
          Usuários
        </Link> */}

          <Link className="button" to="/company">
            <BiBuilding color="#FFF" size={15} />
            Filiais
          </Link>

          <div className="dropdown">
            <div className="dropdown-toggle" onClick={toggleDropdownSAC}>
              <TfiHeadphoneAlt color="#FFF" size={15} />
              SAC
              <AiFillCaretRight className="seta-nav" size={15} />
            </div>
            {isOpenSac && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/SacForm"><BiDetail size={15} />Formulário SAC</Link>
                </li>
                <li>
                </li>
              </ul>
            )}
          </div>



          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );

  }

  else {
    return (
      <div className="sidebar">
        <div className="user">
          <a className="avatar" href="/profile">
            <img
              src={user.avatarUrl === null ? avatar : user.avatarUrl}
              alt="Foto Avatar"
            />
          </a>
        </div>
        <div className="buttons">
          <Link className="button" to="/Inicio">
            <FiHome color="#FFF" size={15} />
            Inicio
          </Link>
          <Link className="button" to="/" onClick={() => signOut()}>
            <FiLogOut size={15} />
            Sair
          </Link>
        </div>
      </div>
    );
  }
}
