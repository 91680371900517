import './users.css';

import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import moment from 'moment';

import { toast } from "react-toastify";
import Header from "../../components/Header";
import Title from "../../components/Title";
import {FiList,FiPlus,FiClipboard,FiEdit2,FiSearch,FiTrash2,FiUsers,} from "react-icons/fi";
import { CgPlayListSearch } from "react-icons/cg";
import { Link } from "react-router-dom";

import firebase from "../../services/firebaseConnection";
import "firebase/firestore";

import ModalFeedback from "../../components/ModalFeedback";


const listRef = firebase.firestore().collection("users");

export default function Feedbacks() {
  const [func, setFunc] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();
  const [todosFeedbacks, setTodosFeedbacks] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const [search, setSearch] = useState("");
  // console.log(search);
  const searchLowerCase = search.toLowerCase();
  const db = firebase.firestore();

  function searchConsultor() {
    const searchLowerCase = search.toLowerCase();
    const userSearch = func.filter(
      (consultor) =>
        consultor.consultor.toLowerCase().includes(searchLowerCase) ||
        consultor.filial.toLowerCase().includes(searchLowerCase) ||
        consultor.dataAnalise.toLowerCase().includes(searchLowerCase) ||
        consultor.nomeCliente.toLowerCase().includes(searchLowerCase) ||
        consultor.responsavel.toLowerCase().includes(searchLowerCase)
    );
    setFunc(userSearch);
  }

  function searchData() {
    const searchLowerCase = search.toLowerCase();
    const userSearch = func.filter(
      (consultor) =>
        consultor.dataAnalise.toLowerCase().includes(searchLowerCase) 
    );
    setFunc(userSearch);
  }

  async function deleteFeedback(id) {
  const confirmDelete = window.confirm("Deseja mesmo excluir o usuário?");
  if (confirmDelete) {
    try {
      // Excluir o usuário do Firestore
      await db.collection("users").doc(id).delete();
      
      // Buscar informações do usuário para excluí-lo do Firebase Authentication
      const userSnapshot = await firebase.auth().getUserByEmail(func.find(user => user.id === id).email);
      const uid = userSnapshot.uid;
      
      // Excluir o usuário do Firebase Authentication
      await firebase.auth().deleteUser(uid);

      // Atualizar o estado após a exclusão
      const userDelete = func.filter((user) => user.id !== id);
      setFunc(userDelete);

      toast.success("Usuário deletado com sucesso!");
    } catch (error) {
      toast.error("Erro ao deletar usuário");
    }
  }
}

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      searchConsultor();
    }
  }
  
  
  useEffect(() => {
    async function loadFeedback() {
      await listRef
        .limit()
        .get()
        .then((snapshot) => {
          updateState(snapshot);
        })
        .catch((err) => {
          console.log("Erro ao buscar", err);
          setLoadingMore(false);
        });

      setLoading(false);
    }

    loadFeedback();

    return () => {};
  }, []);

  useEffect(() => {
    if (search === "") {
      setFunc(todosFeedbacks);
    }
  }, [search]);

  async function updateState(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];

      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          nome: doc.data().nome,
          email: doc.data().email,
          type:doc.data().type
        });
      });

      const lastDoc = snapshot.docs[snapshot.docs.length - 1]; // pegando o ultimo documento buscado

      setFunc(lista);
      setTodosFeedbacks(lista);
      setLastDocs(lastDocs);
    } else {
      setIsEmpty(true);
    }

    setLoadingMore(false);
  }

  async function handleMore() {
    setLoadingMore(true);
    await listRef
      .limit(50)
      .get()
      .then((snapshot) => {
        updateState(snapshot);
      });
  }

  function togglePostModal(item) {
    setShowPostModal(!showPostModal); // Trocando de true para false conforme recebe o valor
    setDetail(item);
  }

  const ordenarCrescente = (index) => {
    const ordenado = [...func].sort((a, b) => {
      if (a[index] < b[index]) {

        return -1;

      }
      if (a[index] > b[index]) {
        return 1;
      }
      return 0;
    });
    setFunc(ordenado);
  }

  const ordenarDecrescente = (index) => {
    const ordenado = [...func].sort((a, b) => {
      if (a[index] > b[index]) {

        return -1;

      }
      if (a[index] < b[index]) {
        return 1;
      }
      return 0;
    });
    setFunc(ordenado);
  }

  function handlePageClick(data) {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  }


  if (loading) {
    return (
      <div>
        <Header />
        <div className="content-colab">
          <Title name="Lista">
            <FiList size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando usuários....</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Lista de usuários">
          <FiUsers size={20} />
        </Title>
        <div className="divSearch">
          <input
            type="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            placeholder="Buscar Consultor"
            
          />
          <button
            className="btn-search"
            onClick={searchConsultor && searchData}
            onKeyDown={(event) => event.key === "Enter" && searchConsultor}
          >
            <FiSearch size={12} color="#FFF" />
            Buscar
          </button>
         
          <input
            className="input-date"
            type="date"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)} 
          />

        </div>

        {func.length === 0 ? (
          <div className="container dashboard">
            <span>Nenhum registro de feedback ...</span>

            <Link to="/new" className="new">
              <FiPlus size={25} color="#FFF" />
              Adicionar
            </Link>
          </div>
        ) : (
          <>
          <table>
              <thead>
                <tr>
                  <th scope="col" style={{display:"none"}}>ID</th>
                  <th scope="col" onClick={() => ordenarCrescente("nome")}>Usuário</th>
                  <th scope="col" onClick={() => ordenarCrescente("email")}>Email</th>
                  <th scope="col" onClick={() => ordenarCrescente("type")}>Permissão</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
              {func
                  .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                  .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Consultor" style={{display:"none"}}>{item.id}</td>
                      <td data-label="Nome Cliente" onClick={() => ordenarDecrescente("nome")}>{item.nome}</td>
                      <td data-label="Numero Cliente" onClick={() => ordenarDecrescente("email")}>{item.email}</td>
                      <td data-label="Numero Cliente" onClick={() => ordenarDecrescente("type")}>{item.type}</td>
                      <td data-label="#">

                      

                       {/* <button
                          className="action"
                          style={{ backgroundColor: "#0B0B34" }}
                          onClick={() => togglePostModal(item)}
                        >
                          <FiClipboard color="#FFF" size={17} />
                        </button>*/}

                        {/* <button
                          className="action"
                          style={{ backgroundColor: "#a4c639" }}
                          onClick= {""}
                        >
                          <FiPhone color="#FFF" size={17} />
                        </button> */}

                       {/* <Link
                          className="action"
                          style={{ backgroundColor: "#f6a935" }}
                          to={`/NewFeedback/${item.id}`}
                        >
                          <FiEdit2 color="#FFF" size={17} />
                        </Link> */}


                        <button
                          className="action"
                          style={{ backgroundColor: "#FF0004" }}
                          onClick={() => deleteFeedback(item.id)}
                        >
                          <FiTrash2 color="#FFF" size={17} />
                        </button>

                        
                      </td>
                    </tr>
                  );
                })}
              </tbody> 
           </table>
           <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              pageCount={Math.ceil(func.length / itemsPerPage)}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />

            <div className="more">
              {loadingMore && (
                <h3 style={{ textAlign: "center", marginTop: 15 }}>
                  Buscando Feedbacks...
                </h3>
              )}
              {!loadingMore && !isEmpty && (
                <button className="btn-more" onClick={handleMore}>
                  <CgPlayListSearch size={17} color="#FFF" />
                  Buscar Mais
                </button>
              )}
              <div className="novoColaborador">
                <Link to="/register" className="new">
                  <FiPlus size={15} color="#FFF" />
                  Novo usuário
                </Link>
              </div>
            </div>
          </>
        )}
      </div>

      {showPostModal && <ModalFeedback conteudo={detail} close={togglePostModal} />}
    </div>
  );
}
