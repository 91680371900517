import './company.css';

import Header from '../../components/Header';
import Title from '../../components/Title';
import avatar from '../../assets/avatar.png';
import { BiBuilding } from "react-icons/bi";

import turismo from '../../assets/filiais/turismo.png';
import filial01 from '../../assets/filiais/filial01.jpg';
import filial02 from '../../assets/filiais/filial02.png';
import filial04 from '../../assets/filiais/ponteio.png';
import filial05 from '../../assets/filiais/caratinga.png';
import filial06 from '../../assets/filiais/iguaçu.png';
import manhuaçu from '../../assets/filiais/manhuaçu.png';
import shoppingdovale from '../../assets/filiais/shopping-do-vale.png'

export default function Company() {
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Filiais">
          <BiBuilding size={25} />
        </Title>

        <div className="cards-container">
          <div className="card">
            <img src="" alt="OctaDesk" />
            <h2>Matriz</h2>
            <p>Avenida Simon Bolivar, nº 1160, Sala 302,
              Cidade Nobre - Ipatinga - MG</p>
              <a style={{width:"65px"}} href="https://goo.gl/maps/LKE1CTgL8viZmxYD6" target="_blank" rel="noreferrer">Visualizar</a>
          </div>
          <div className="card">
            <img src={filial01} alt="App América" />
            <h2>Filial 1</h2>
            <p>Avenida Monteiro Lobato, n° 602,
              Cidade Nobre, Ipatinga - MG</p>
              <a style={{width:"65px"}} href="https://goo.gl/maps/JocFS7gZN7piGTV76" target="_blank" rel="noreferrer">Visualizar</a>
          </div>

          <div className="card">
            <img src={filial02} alt="Escallo" />
            <div className='tittle'>
              <h2>Filial 2</h2>
              <p>Rua Mariana, n° 155, loja 02,
                Centro, Ipatinga - MG</p>
            </div>
            <a style={{width:"65px"}} href="https://goo.gl/maps/pCWBCCCWugXwM5wm6" target="_blank" rel="noreferrer">Visualizar</a>
          </div>

          <div className="card">
            <img src={shoppingdovale} alt="Descrição da imagem" />
            <div className='tittle'>
              <h2>Filial 3</h2>
              <p>Av. Pedro Linhares Gomes, n° 3900 - Industrial
                Shopping Vale do Aço, Ipatinga - MG</p>
            </div>
            <a style={{width:"65px"}} href="https://goo.gl/maps/zjH7fCBZgYN29q6d8" target="_blank" rel="noreferrer">Visualizar</a>
          </div>


        </div>


        <div className="cards-container">
          <div className="card">
            <img src={filial04} alt="OctaDesk" />
            <h2>Filial 4</h2>
            <p>BR-356. nº 2500, Ao lado da Policia Federal
              Santa Lúcia, Ponteio Lar Shopping, Belo Horizonte - MG</p>
              <a style={{width:"65px"}} href="https://goo.gl/maps/Rs7od3rjSDtcniNn8" target="_blank" rel="noreferrer">Visualizar</a>
          </div>
          <div className="card">
            <img src={filial05} alt="App América" />
            <h2>Filial 5</h2>
            <p>Av. Marechal Deodoro da Fonseca, n° 84
              Centro, Caratinga - MG</p>
              <a style={{width:"65px"}} href="https://goo.gl/maps/rv2JFqzoBVv2dH4L9" target="_blank" rel="noreferrer">Visualizar</a>
          </div>

          <div className="card">
            <img src={filial06} alt="Escallo" />
            <h2>Filial 6</h2>
            <p> Av. Brasil, n° 595
              Iguaçu, Ipatinga - MG</p>
              <a style={{width:"65px"}} href="https://goo.gl/maps/UZGUh6dVx5x3YriN9" target="_blank" rel="noreferrer">Visualizar</a>
          </div>

          <div className="card">
            <img src={manhuaçu} alt="Descrição da imagem" />
            <h2>Credenciada Manhuaçu MG</h2>
            <p>Rua Leandro Gonçalves, n° 80
              Centro, Manhuaçu - MG</p>
              <a style={{width:"65px"}} href="https://goo.gl/maps/ZvBKq2GPkQ4b6yFt9" target="_blank" rel="noreferrer">Visualizar</a>
          </div>


        </div>

        <div className="cards-container">
          <div className="card">
            <img src={avatar} alt="OctaDesk" />
            <h2>Credenciada Teófilo Otoni MG</h2>
            <p>Rua Doutor Manoel Esteves, n°112</p>
            
            <a style={{width:"65px"}} href="https://goo.gl/maps/kiiBySZJUWZ2UvWMA" target="_blank" rel="noreferrer">Visualizar</a>
          </div>
          <div className="card">
            <img src={avatar} alt="App América" />
            <h2>Credenciada Guanhães MG</h2>
            <p>Av. Marechal Deodoro da Fonseca, n° 84
              Centro, Caratinga - MG</p>

          </div>

          <div className="card">
            <img src={avatar} alt="Escallo" />
            <h2>Credenciada Vila Velha ES</h2>
            <p> Rua Henrique Moscoso, 459 - Praia da Costa
              Vila Vela - ES</p>
              <a style={{width:"65px"}} href="https://goo.gl/maps/tsSbqC82JDEHiBXN7" target="_blank" rel="noreferrer">Visualizar</a>
          </div>

      


        </div>
      </div>
    </div>
  )
}