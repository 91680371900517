import './resetpassword.css';
import logo from '../../assets/logoamerica.png'
import { useState } from 'react';

import firebase from "../../services/firebaseConnection";
import "firebase/firestore";
import { toast } from 'react-toastify';

import { Link } from "react-router-dom";


export default function ResetPassword() {

    const [email, setEmail] = useState("");

    function recoverPassword() {

        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                toast.success("Email enviado com sucesso!");
                setEmail("");
            }).catch((error) => {
                toast.error("Digite seu email no login!")
            });
    }



    return (
        <div className='container-reset'>
            <div className='reset-password'>
            <div className='image-reset-password'>
                <img src={logo} alt="Sistema Logo" />
            </div>
                <h4 className='title-reset'>Insira seu email para recuperar sua senha</h4>
                <input type='text' placeholder='email' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                <button type='submit' onClick={recoverPassword}>Enviar</button>
                <Link className='recoveryPassword' to="/">Entrar no sistema</Link>
            </div>
        </div>
    )
}