import { useState } from 'react';
import './customers.css';
import Title from '../../components/Title';
import Header from '../../components/Header';
import firebase from '../../services/firebaseConnection';
import {FiUser} from 'react-icons/fi';

import { toast } from 'react-toastify';


export default function Customers(){
    
    const [nome,setNome] = useState('');
    const [email,setEmail] = useState('');
    const [ramal,setRamal] = useState('');
    const [setor,setSetor] = useState('');
    const [funcao,setFuncao] = useState('');
    const [filial,setFilial] = useState('');
    
    async function handleAdd(e){
        e.preventDefault();
        
        if(nome !== '' && email !== '' && ramal !== '' && setor !== '' && funcao !== '' && filial !== ''){
            await firebase.firestore().collection('customers')
            .add({
                nome: nome,
                email: email,
                ramal: ramal,
                setor: setor,
                funcao: funcao,
                filial: filial
            })
            .then(()=>{
                setNome('');
                setEmail('');
                setRamal('');
                setSetor('');
                setFuncao('');
                setFilial('');

                toast.info('Usuário cadastrado com sucesso!');
            })  
                .catch((error)=>{
                    console.log(error);
                    toast.error('Erro ao cadastrar usuário');
                })
            
        }else{
            toast.error('Preencha todos os campos!');
        }
    }


    return(
        <div>
            <Header/>
            <div className='content'>
                <Title name="Usuários">
                    <FiUser size={25}/>
                </Title>

                <div className='container'>
                    <form className='form-profile customers' onClick={handleAdd} >
                        <label>Nome</label>
                        <input type="text" placeholder='Nome' value={nome} onChange={(e)=> setNome(e.target.value)} />

                        <label>Email</label>
                        <input type="text" placeholder='Seu email' value={email} onChange={(e)=> setEmail(e.target.value)} />

                        <label>Ramal</label>
                        <input type="text" placeholder='N° Ramal' value={ramal} onChange={(e)=> setRamal(e.target.value)} />

                        <label>Setor</label>
                        <input type="text" placeholder='Setor' value={setor} onChange={(e)=> setSetor(e.target.value)} />

                        <label>Função</label>
                        <input type="text" placeholder='Função' value={funcao} onChange={(e)=> setFuncao(e.target.value)} />

                        <label>Filial</label>
                        <input type="text" placeholder='Filial' value={filial} onChange={(e)=> setFilial(e.target.value)} />

                        <button type='submit'>Cadastrar</button>
                        
                    </form>
                </div>
            </div>
        </div>
    )
}