import './baseleads.css';
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import Title from "../../components/Title";
import {
    FiList,
    FiPlus,
    FiClipboard,
    FiEdit2,
    FiSearch,
    FiTrash2,
    FiUsers,
    FiPhone
} from "react-icons/fi";
import { CgPlayListSearch } from "react-icons/cg";
import { SiMicrosoftexcel } from "react-icons/si"
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';

import firebase from "../../services/firebaseConnection";
import "firebase/firestore";

import { AuthContext } from "../../contexts/auth";
import { useContext } from "react";

const listRef = firebase.firestore().collection("leads");

export default function BaseLeads() {
    const [func, setFunc] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [lastDocs, setLastDocs] = useState();

    const [showPostModal, setShowPostModal] = useState(false);
    const [detail, setDetail] = useState();
    const [todosColaboradores, setTodosColaboradores] = useState([]);

    const [searchSetor, setSearchSetor] = useState("");
    const [filialSearch, setFilialSearch] = useState("");

    const [search, setSearch] = useState("");
    const [filtrosTable, setFiltroTable] = useState([]);
    // console.log(search);/
    const searchLowerCase = search.toLowerCase();
    const db = firebase.firestore();

    const { user } = useContext(AuthContext);
    const callname = user && user.nome;
    const type = user && user.type;

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    function searchColaborador() {
        const searchLowerCase = search.toLowerCase();
        const userSearch = func.filter(
            (colaborador) =>
                colaborador.codigo.toLowerCase().includes(searchLowerCase) ||
                colaborador.nome.toLowerCase().includes(searchLowerCase) ||
                colaborador.data.toLowerCase().includes(searchLowerCase) ||
                colaborador.consultor.toLowerCase().includes(searchLowerCase) ||
                colaborador.captador.toLowerCase().includes(searchLowerCase) ||
                colaborador.status.toLowerCase().includes(searchLowerCase) ||
                colaborador.indicacao.toLowerCase().includes(searchLowerCase) ||
                colaborador.indicacao2.toLowerCase().includes(searchLowerCase) ||
                colaborador.indicacao3.toLowerCase().includes(searchLowerCase) ||
                colaborador.indicacao4.toLowerCase().includes(searchLowerCase) ||
                colaborador.indicacao5.toLowerCase().includes(searchLowerCase) ||
                colaborador.transferido.toLowerCase().includes(searchLowerCase) ||
                colaborador.dataCriacao.toLowerCase().includes(searchLowerCase)
        );
        setFunc(userSearch);
    }

    function searchFiliais() {
        const searchLowerCase = filialSearch.toLowerCase();
        const userSearch = func.filter(
            (colaborador) =>
                colaborador.filial.toLowerCase().includes(searchLowerCase)
        );
        setFunc(userSearch);
    }

    // function searchSetores() {
    //   const searchLowerCase = searchSetor.toLowerCase();
    //   const setoresSearch = func.filter(
    //     (colaborador) =>
    //       colaborador.setor.toLowerCase().includes(searchLowerCase)
    //   );
    //   setFunc(setoresSearch);
    // }

    function deleteColaborador(id) {
        const confirmDelete = window.confirm("Deseja mesmo excluir o registro?");
        if (confirmDelete) {
            const userDelete = func.filter((colaborador) => colaborador.id !== id);
            setFunc(userDelete);

            db.collection("leads")
                .doc(id)
                .delete()
                .then(() => {
                    toast.success("Registro deletado com sucesso!");
                })
                .catch(() => {
                    toast.error("Erro ao deletar");
                });
        } else {
            return;
        }
    }

    function handleKeyDown(e) {
        if (e.key === "Enter") {
            searchColaborador();
        }
    }




    useEffect(() => {
        async function loadColaborador() {
            await listRef
                .limit()
                .get()
                .then((snapshot) => {
                    updateState(snapshot);
                })
                .catch((err) => {
                    console.log("Erro ao buscar", err);
                    setLoadingMore(false);
                });

            setLoading(false);
        }

        loadColaborador();

        return () => { };
    }, []);

    useEffect(() => {
        if (search === "") {
            setFunc(todosColaboradores);
        }
    }, [search]);



    const exportData = async () => {
        const worksheet = XLSX.utils.json_to_sheet(func); // converte os dados em uma planilha Excel
        const workbook = XLSX.utils.book_new(); // cria um novo livro de Excel
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados'); // adiciona a planilha de dados ao livro de Excel
        XLSX.writeFile(workbook, 'tabela-call.xlsx'); // baixa o arquivo Excel com os dados

    };




    async function updateState(snapshot) {
        const isCollectionEmpty = snapshot.size === 0;

        if (!isCollectionEmpty) {
            let lista = [];

            snapshot.forEach((doc) => {
                lista.push({
                    id: doc.id,
                    codigo: doc.data().codigo,
                    nome: doc.data().nome,
                    data: doc.data().data,
                    consultor: doc.data().consultor,
                    captador: doc.data().captador,
                    status: doc.data().status,
                    indicacao: doc.data().indicacao,
                    indicacao2: doc.data().indicacao2,
                    indicacao3: doc.data().indicacao3,
                    indicacao4: doc.data().indicacao4,
                    indicacao5: doc.data().indicacao5,
                    transferido: doc.data().transferido,
                    dataCriacao: doc.data().dataCriacao,
                });
            });

            const lastDoc = snapshot.docs[snapshot.docs.length - 1]; // pegando o ultimo documento buscado

            setFunc(lista);
            setTodosColaboradores(lista);
            setLastDocs(lastDocs);
        } else {
            setIsEmpty(true);
        }

        setLoadingMore(false);
    }

    async function handleMore() {
        setLoadingMore(true);
        await listRef
            .limit(50)
            .get()
            .then((snapshot) => {
                updateState(snapshot);
            });
    }

    function togglePostModal(item) {
        setShowPostModal(!showPostModal); // Trocando de true para false conforme recebe o valor
        setDetail(item);
    }

    const ordenarCrescente = (index) => {
        const ordenado = [...func].sort((a, b) => {
            if (a[index] < b[index]) {

                return -1;

            }
            if (a[index] > b[index]) {
                return 1;
            }
            return 0;
        });
        setFunc(ordenado);
    }

    const ordenarDecrescente = (index) => {
        const ordenado = [...func].sort((a, b) => {
            if (a[index] > b[index]) {

                return -1;

            }
            if (a[index] < b[index]) {
                return 1;
            }
            return 0;
        });
        setFunc(ordenado);
    }

    function handlePageClick(data) {
        const selectedPage = data.selected;
        setCurrentPage(selectedPage);
    }


    if (loading) {
        return (
            <div>
                <Header />
                <div className="content">
                    <Title name="Lista">
                        <FiList size={25} />
                    </Title>

                    <div className="container dashboard">
                        <span>Buscando registros....</span>
                    </div>
                </div>
            </div>
        );
    }


   
    if (type === "Gerenciamento de Leads") {
        return (
            <div>
                <Header />
                <div className="content">
                    <Title name="Lista de registros">
                        <FiUsers size={20} />
                    </Title>
                    <div className="divSearch">
                        <input
                            type="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            placeholder="Buscar"

                        />



                        <button
                            className="btn-search"
                            onClick={searchColaborador && searchFiliais}
                            onKeyDown={(event) => event.key === "Enter" && searchColaborador}
                        >
                            <FiSearch size={12} color="#FFF" />
                            Buscar
                        </button>

                        <Link to="/NewBaseLeads" className="new-register">
                            <FiPlus size={15} color="#FFF" />
                            Novo registro
                        </Link>





                        <button className="btn-export" onClick={exportData}><SiMicrosoftexcel size={15} />Exportar</button>





                    </div>

                    {func.length === 0 ? (
                        <div className="container dashboard">
                            <span>Nenhum registro ...</span>

                            <Link to="/NewBaseLeads" className="new">
                                <FiPlus size={25} color="#FFF" />
                                Adicionar
                            </Link>
                        </div>
                    ) : (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => ordenarCrescente("codigo")}>Código</th>
                                        <th scope="col" onClick={() => ordenarCrescente("nome")}>Nome</th>
                                        <th scope="col" onClick={() => ordenarCrescente("data")} >Data</th>
                                        <th scope="col" onClick={() => ordenarCrescente("consultor")}>Consultor</th>
                                        <th scope="col" onClick={() => ordenarCrescente("captador")}>Captador</th>
                                        <th scope="col" onClick={() => ordenarCrescente("status")}> Status</th>
                                        <th scope="col"> Indicação 1</th>
                                        <th scope="col"> Indicação 2</th>
                                        <th scope="col"> Indicação 3</th>
                                        <th scope="col"> Indicação 4</th>
                                        <th scope="col"> Indicação 5</th>
                                        <th scope="col" onClick={() => ordenarCrescente("transferido")}> Transferido</th>
                                        <th scope="col" onClick={() => ordenarCrescente("dataCriacao")}> Data Criação</th>
                                        <th scope="col">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {func
                                        .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                                        .map((item, index) => {
                                            const isCaptador = item.captador === callname;
                                            return isCaptador ? (

                                                <tr key={index}>
                                                    <td data-label="Código">{item.codigo}</td>
                                                    <td data-label="Nome" onClick={() => ordenarDecrescente("nome")}>{item.nome}</td>
                                                    <td data-label="Data" >{item.data}</td>
                                                    <td data-label="Consultor" >{item.consultor}</td>
                                                    <td data-label="Captador">{item.captador === callname ? item.captador : ''}</td>
                                                    <td data-label="Status" font="bold" style={{
                                                        fontWeight: 'bold',
                                                        color: item.status === 'Contrato' ? '#8A2BE2' :
                                                            item.status === 'Sem resposta' ? '#696969' :
                                                                item.status === 'Wpp' ? '#008000' :
                                                                    item.status === 'Em tratativa' ? '#0000FF' :
                                                                        item.status === 'Sem interesse' ? '#FF0000' :
                                                                            item.status === 'Estrangeiro' ? '#B22222' :
                                                                                item.status === 'Atendido/wpp' ? '#F08080' :
                                                                                    item.status === 'Já possui visto' ? '#EE82EE' :
                                                                                        item.status === 'Indicou' ? '#4682B4' : '#f8f8f8'
                                                    }} >{item.status}</td>
                                                    <td data-label="Indicação 1" >{item.indicacao}</td>
                                                    <td data-label="Indicação 2" >{item.indicacao2}</td>
                                                    <td data-label="Indicação 3" >{item.indicacao3}</td>
                                                    <td data-label="Indicação 4" >{item.indicacao4}</td>
                                                    <td data-label="Indicação 5" >{item.indicacao5}</td>
                                                    <td data-label="Transferido" >{item.transferido}</td>
                                                    <td data-label="Data Criação">{item.dataCriacao}</td>
                                                    <td data-label="#">




                                                        {/* <button
                          className="action"
                          style={{ backgroundColor: "#a4c639" }}
                          onClick= {""}
                        >
                          <FiPhone color="#FFF" size={17} />
                        </button> */}

                                                        <Link
                                                            className="action"
                                                            style={{ backgroundColor: "#f6a935" }}
                                                            to={`NewBaseLeads/${item.id}`}
                                                        >
                                                            <FiEdit2 color="#FFF" size={17} />
                                                        </Link>


                                                        <button
                                                            className="action"
                                                            style={{ backgroundColor: "#FF0004" }}
                                                            onClick={() => deleteColaborador(item.id)}
                                                        >
                                                            <FiTrash2 color="#FFF" size={17} />
                                                        </button>


                                                    </td>
                                                </tr>
                                            ) : null;
                                        })}
                                </tbody>
                            </table>
                            <ReactPaginate
                                previousLabel={"Anterior"}
                                nextLabel={"Próximo"}
                                pageCount={Math.ceil(func.length / itemsPerPage)}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />

                            <div className="more">
                                {loadingMore && (
                                    <h3 style={{ textAlign: "center", marginTop: 15 }}>
                                        Buscando colaboradores...
                                    </h3>
                                )}
                                {!loadingMore && !isEmpty && (
                                    <button className="btn-more" onClick={handleMore}>
                                        <CgPlayListSearch size={17} color="#FFF" />
                                        Buscar Mais
                                    </button>
                                )}

                            </div>
                        </>
                    )}
                </div>


            </div>
        )
    } else if (type === "TI" || type === "Gestão comercial") {
        return (
            <div>
                <Header />
                <div className="content">
                    <Title name="Lista de registros">
                        <FiUsers size={20} />
                    </Title>
                    <div className="divSearch">
                        <input
                            type="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            placeholder="Buscar"

                        />



                        <button
                            className="btn-search"
                            onClick={searchColaborador && searchFiliais}
                            onKeyDown={(event) => event.key === "Enter" && searchColaborador}
                        >
                            <FiSearch size={12} color="#FFF" />
                            Buscar
                        </button>


                        <Link to="/NewBaseLeads" className="new-register">
                            <FiPlus size={15} color="#FFF" />
                            Novo registro
                        </Link>






                        <button className="btn-export" onClick={exportData}><SiMicrosoftexcel size={15} />Exportar</button>





                    </div>

                    {func.length === 0 ? (
                        <div className="container dashboard">
                            <span>Nenhum registro ...</span>

                            <Link to="/NewBaseLeads" className="new">
                                <FiPlus size={25} color="#FFF" />
                                Adicionar
                            </Link>
                        </div>
                    ) : (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => ordenarCrescente("codigo")}>Código</th>
                                        <th scope="col" onClick={() => ordenarCrescente("nome")}>Nome</th>
                                        <th scope="col" onClick={() => ordenarCrescente("data")} >Data</th>
                                        <th scope="col" onClick={() => ordenarCrescente("consultor")}>Consultor</th>
                                        <th scope="col" onClick={() => ordenarCrescente("captador")}>Captador</th>
                                        <th scope="col" onClick={() => ordenarCrescente("status")}> Status</th>
                                        <th scope="col"> Indicação 1</th>
                                        <th scope="col"> Indicação 2</th>
                                        <th scope="col"> Indicação 3</th>
                                        <th scope="col"> Indicação 4</th>
                                        <th scope="col"> Indicação 5</th>
                                        <th scope="col" onClick={() => ordenarCrescente("transferido")}> Transferido</th>
                                        <th scope="col" onClick={() => ordenarCrescente("dataCriacao")}> Data Criação</th>
                                        <th scope="col">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {func
                                        .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                                        .map((item, index) => {

                                            return (

                                                <tr key={index}>
                                                    <td data-label="Código">{item.codigo}</td>
                                                    <td data-label="Nome" onClick={() => ordenarDecrescente("nome")}>{item.nome}</td>
                                                    <td data-label="Data" >{item.data}</td>
                                                    <td data-label="Consultor" >{item.consultor}</td>
                                                    <td data-label="Captador">{item.captador}</td>
                                                    <td data-label="Status" font="bold" style={{
                                                        fontWeight: 'bold',
                                                        color: item.status === 'Contrato' ? '#8A2BE2' :
                                                            item.status === 'Sem resposta' ? '#696969' :
                                                                item.status === 'Wpp' ? '#008000' :
                                                                    item.status === 'Em tratativa' ? '#0000FF' :
                                                                        item.status === 'Sem interesse' ? '#FF0000' :
                                                                            item.status === 'Estrangeiro' ? '#B22222' :
                                                                                item.status === 'Atendido/wpp' ? '#F08080' :
                                                                                    item.status === 'Já possui visto' ? '#EE82EE' :
                                                                                        item.status === 'Indicou' ? '#4682B4' : '#f8f8f8'
                                                    }} >{item.status}</td>
                                                    <td data-label="Indicação 1" >{item.indicacao}</td>
                                                    <td data-label="Indicação 2" >{item.indicacao2}</td>
                                                    <td data-label="Indicação 3" >{item.indicacao3}</td>
                                                    <td data-label="Indicação 4" >{item.indicacao4}</td>
                                                    <td data-label="Indicação 5" >{item.indicacao5}</td>
                                                    <td data-label="Transferido" >{item.transferido}</td>
                                                    <td data-label="Data Criação">{item.dataCriacao}</td>
                                                    <td data-label="#">





                                                        {/* <button
                              className="action"
                              style={{ backgroundColor: "#a4c639" }}
                              onClick= {""}
                            >
                              <FiPhone color="#FFF" size={17} />
                            </button> */}

                                                        <Link
                                                            className="action"
                                                            style={{ backgroundColor: "#f6a935" }}
                                                            to={`NewBaseLeads/${item.id}`}
                                                        >
                                                            <FiEdit2 color="#FFF" size={17} />
                                                        </Link>


                                                        <button
                                                            className="action"
                                                            style={{ backgroundColor: "#FF0004" }}
                                                            onClick={() => deleteColaborador(item.id)}
                                                        >
                                                            <FiTrash2 color="#FFF" size={17} />
                                                        </button>


                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                            <ReactPaginate
                                previousLabel={"Anterior"}
                                nextLabel={"Próximo"}
                                pageCount={Math.ceil(func.length / itemsPerPage)}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />

                            <div className="more">
                                {loadingMore && (
                                    <h3 style={{ textAlign: "center", marginTop: 15 }}>
                                        Buscando colaboradores...
                                    </h3>
                                )}
                                {!loadingMore && !isEmpty && (
                                    <button className="btn-more" onClick={handleMore}>
                                        <CgPlayListSearch size={17} color="#FFF" />
                                        Buscar Mais
                                    </button>
                                )}

                            </div>
                        </>
                    )}
                </div>

            </div>
        )
    }
}
