import "./dashboard.css";

// import Header from "../../components/Header";
// import Title from "../../components/Title";
import hotBg from "./../../assets/calor.jpg";
import coldBg from "./../../assets/frio.jpg";
import Descriptions from "./../../components/Descriptions";
import { useEffect, useState } from "react";
import { getFormattedWeatherData } from "./../../services/weatherService";
import Header from "../../components/Header";
import DollarCharts from "../../components/DolarCharts";
import DollarValue from "../../components/Dolar";
import CarouselDp from "../../components/Carousel-Dp";

import Swal from 'sweetalert2';
import moment from 'moment';
import Confetti from 'react-confetti';
import { AuthContext } from "../../contexts/auth";
import { useContext } from "react";
import Clock from "./../../components/Clock";

export default function Dashboard() {
  const [city, setCity] = useState("New York");
  const [weather, setWeather] = useState(null);
  const [units, setUnits] = useState("metric");
  const [bg, setBg] = useState(hotBg);
  const { user } = useContext(AuthContext);

  const users = user && user.nome;
  const dataNasc = user && user.date;
  const [avatarUrl, setAvatarUrl] = useState(user && user.avatarUrl);

  const data = new Date();
  const day2 = moment(data).format("DD/MM")
  const birthday = moment(dataNasc).format("DD/MM")

  const [isConfettiRunning, setIsConfettiRunning] = useState(false);


  function startConfetti() {
    setIsConfettiRunning(true);
    setTimeout(() => {
      setIsConfettiRunning(false);
    }, 15000)
  }

  console.log(DollarValue)

  const handleSweet = () => {


    if (birthday === day2 && users) {

      Swal.fire({
        title: 'Parabéns, ' + users,
        html: 'Receba os nossos parabéns pelo seu dia! Aproveite ao máximo e que nunca lhe falte felicidade amor, saúde e amizade!',
        timer: 15000,
        imageUrl: avatarUrl,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: 'Custom image',
        customClass: { image: "rounded-image" },
        background: 'rgba(255,255,255,0.4)a url(../../assets/americamusic.png)',
        backdrop: `
    rgba(0,0,123,0.4)
    url("/images/nyan-cat.gif")
    left top
    no-repeat
  `
      })

    }
  }


  useEffect(() => {
    const fetchWeatherData = async () => {
      const data = await getFormattedWeatherData(city, units);
      setWeather(data);

      // dynamic bg
      const threshold = units === "metric" ? 20 : 60;
      if (data.temp <= threshold) setBg(coldBg);
      else setBg(hotBg);
    };

    fetchWeatherData();
  }, [units, city]);

  const handleUnitsClick = (e) => {
    const button = e.currentTarget;
    const currentUnit = button.innerText.slice(1);

    const isCelsius = currentUnit === "C";
    button.innerText = isCelsius ? "°F" : "°C";
    setUnits(isCelsius ? "metric" : "imperial");
  };

  const enterKeyPressed = (e) => {
    if (e.keyCode === 13) {
      setCity(e.currentTarget.value);
      e.currentTarget.blur();
    }
  };

  if (birthday === day2 && users) {
    return (
      <div
        className="container-dashboard"
      >
        <Header />
        <div className="content-initial" onLoad={startConfetti}>
          {isConfettiRunning && <Confetti run={isConfettiRunning} />}
          <div className="app-initial" onLoad={handleSweet}>
            <div className="overlay-init">
              {weather && (
                <div className="container-initial">
                  <div className="section section__inputs">
                    <input
                      onKeyDown={enterKeyPressed}
                      type="text"
                      name="city"
                      placeholder="Buscar Cidade"
                    />
                    <button onClick={(e) => handleUnitsClick(e)}>°F</button>
                  </div>

                  <div className="section section__temperature">
                    <div className="icon">
                      <h3>{`${weather.name}, ${weather.country}`}</h3>
                      <img src={weather.iconURL} alt="weatherIcon" />
                      <h3>{weather.description}</h3>
                    </div>
                    <div className="temperature">
                      <h1>{`${weather.temp.toFixed()} °${units === "metric" ? "C" : "F"
                        }`}</h1>
                    </div>
                  </div>
                  <div className="section section__dolar">


                    <DollarValue />
                    <DollarCharts />

                  </div>
                </div>
              )}
            </div>
          </div>


          <div className="section section__niver">

            <CarouselDp className="carousel-image" />


          </div>


        </div>

      </div>
    );
  }


  return (
    <div
      className="container-dashboard"

    >
      <Header />
      <div className="content-initial">

        <div className="app-initial">
          <div className="overlay-init">
            {weather && (
              <div className="container-initial">
                <div className="section section__inputs">
                  <input
                    onKeyDown={enterKeyPressed}
                    type="text"
                    name="city"
                    placeholder="Buscar Cidade"
                  />
                  <button onClick={(e) => handleUnitsClick(e)}>°F</button>
                </div>

                <div className="section section__temperature">
                  <div className="icon">
                    <h3>{`${weather.name}, ${weather.country}`}</h3>
                    <img src={weather.iconURL} alt="weatherIcon" />
                    <h3>{weather.description}</h3>
                  </div>
                  <div className="temperature">
                    <h1>{`${weather.temp.toFixed()} °${units === "metric" ? "C" : "F"
                      }`}</h1>
                  </div>
                </div>
                <div className="section section__dolar">


                  <DollarValue />
                  <DollarCharts />

                </div>
                <div className="section section__faixa">


              <Clock/>

                </div>

              </div>
            )}
          </div>
        </div>


        <div className="section section__niver">

          <CarouselDp />


        </div>


      </div>

    </div>
  );

}