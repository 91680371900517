import "./links.css";

import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiShare2 } from "react-icons/fi";

import zenvia from "../../assets/zenvia.png";

export default function Links() {
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Links Utéis">
          <FiShare2 size={25} />
        </Title> 

        <div className="cards-container">
          
          <div className="card">
            <img src="https://i.postimg.cc/g06DR9Mv/favicon-ico.png" alt="App América" />
                <h2>App América</h2>
                <p>Sistema do Grupo América</p>
                <a href="https://painel.americavistos.com.br" target="_blank" rel="noreferrer">Acessar</a>
          </div>


          <div className="card">
            <img src={zenvia} alt="Zenvia" />
            <h2>Zenvia</h2>
            <p>Sistema de atendimento ao cliente</p>
            <a href="https://web.zenvia.com/zenvia-conversion" target="_blank" rel="noreferrer">Acessar</a>
          </div>
          
          <div className="card">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiktXvywMwEtcSt-Adr4_6Lt8sPJDzt2Iwyxs_NQ-z8A&s" alt="Descrição da imagem" />
            <h2>Milvus</h2>
            <p>Sistema para abertura de chamados</p>
            <a href="https://app.milvus.com.br/" target="_blank" rel="noreferrer">Acessar</a>
          </div>
        </div>
      </div>
    </div>
  );
}
