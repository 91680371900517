import './colaboradores.css';
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import firebase from "../../services/firebaseConnection";
import "firebase/firestore";
import { toast } from 'react-toastify';
import Header from "../../components/Header";
import Title from "../../components/Title";
import {
  FiList,
  FiPlus,
  FiClipboard,
  FiEdit2,
  FiSearch,
  FiTrash2,
  FiUsers,
} from "react-icons/fi";
import { Link } from "react-router-dom";

import Modal from "../../components/Modal";

const itemsPerPage = 10;
const listRef = firebase.firestore().collection("colaboradores");

export default function Dashboard() {
  const [func, setFunc] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState([]);
  const db = firebase.firestore();

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function loadColaborador() {
      setLoading(true);
      const snapshot = await listRef.get();
      const lista = [];

      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          nome: doc.data().nome,
          email: doc.data().email,
          filial: doc.data().filial,
          ramal: doc.data().ramal,
          setor: doc.data().setor,
          funcao: doc.data().funcao,
          dataNascimento: doc.data().dataNascimento,
        });
      });

      setFunc(lista);
      setLoading(false);
    }

    loadColaborador();
  }, []);

  function handlePageClick(data) {
    const selected = data.selected;
    setCurrentPage(selected);
  }

  const displayedFuncs = func
    .filter((item) => {
      const searchLowerCase = searchTerm.toLowerCase();
      return (
        item.nome.toLowerCase().includes(searchLowerCase) ||
        item.setor.toLowerCase().includes(searchLowerCase) ||
        item.filial.toLowerCase().includes(searchLowerCase) ||
        item.ramal.toLowerCase().includes(searchLowerCase) ||
        item.funcao.toLowerCase().includes(searchLowerCase) ||
        item.email.toLowerCase().includes(searchLowerCase)
      );
    });

  function togglePostModal(item) {
    setShowPostModal(!showPostModal);
    setDetail(item);
  }



  function deleteColaborador(id) {
    const confirmDelete = window.confirm("Deseja mesmo excluir o usuário?");
    if (confirmDelete) {
      const userDelete = func.filter((colaborador) => colaborador.id !== id);
      setFunc(userDelete);

      db.collection("colaboradores")
        .doc(id)
        .delete()
        .then(() => {
          toast.success("Colaborador deletado com sucesso!");
        })
        .catch(() => {
          toast.error("Erro ao deletar colaborador");
        });
    } else {
      return;
    }
  }

  







  if (loading) {
    return (
      <div>
        <Header />
        <div className="content">
          <Title name="Lista">
            <FiList size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando colaboradores....</span>
          </div>
        </div>
      </div>
    );
  }

  const pageCount = Math.ceil(displayedFuncs.length / itemsPerPage);

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Lista de Colaboradores">
          <FiUsers size={20} />
        </Title>

        <div className="divSearch">
          <input
            type="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Buscar"
          />
          <button
            className="btn-search"
            onClick={() => setCurrentPage(0)} // Reinicia a página para a primeira página ao realizar a pesquisa
          >
            <FiSearch size={12} color="#FFF" />
            Buscar
          </button>
          <Link to="/new" className="new">
                  <FiPlus size={15} color="#FFF" />
                  Novo usuário
                </Link>

                <Link to="/new" className="new-mobile">
                  <FiPlus size={20} color="#FFF" />
                  
                </Link>
        </div>
        

        

        {displayedFuncs.length === 0 ? (
          <div className="container dashboard">
            <span>Nenhum registro de funcionário ...</span>

            <Link to="/new" className="new">
              <FiPlus size={25} color="#FFF" />
              Adicionar
            </Link>
          </div>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Email</th>
                  <th scope="col">Filial</th>
                  <th scope="col">Ramal</th>
                  <th scope="col">Setor</th>
                  <th scope="col" style={{ display: 'none' }}>Função</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <tbody>
                {displayedFuncs
                  .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.nome}</td>
                      <td>{item.email}</td>
                      <td>{item.filial}</td>
                      <td>{item.ramal}</td>
                      <td>{item.setor}</td>
                      <td style={{ display: 'none' }}>{item.funcao}</td>
                      <td>
                        <button
                          className="action"
                          style={{ backgroundColor: "#0B0B34" }}
                          onClick={() => togglePostModal(item)}
                        >
                          <FiClipboard color="#FFF" size={17} />
                        </button>

                        <Link
                          className="action"
                          style={{ backgroundColor: "#f6a935" }}
                          to={`/new/${item.id}`}
                        >
                          <FiEdit2 color="#FFF" size={17} />
                        </Link>

                        <button
                          className="action"
                          style={{ backgroundColor: "#FF0004" }}
                          onClick={() => deleteColaborador(item.id)}
                        >
                          <FiTrash2 color="#FFF" size={17} />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </>
        )}
      </div>

      {showPostModal && <Modal conteudo={detail} close={togglePostModal} />}
    </div>
  );
}
