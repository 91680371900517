import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    adaptiveHeight: true
    
  };

  return (
    <div className="carousel">
      <Slider {...settings}>
        <div className="carousel-img">
          <img src="https://i.postimg.cc/fk4F9x2z/insta-america-3.jpg" alt="Imagem 1" />
        </div>
        <div className="carousel-img">
          <img src="https://i.postimg.cc/BQKtf42H/insta-america-4.jpg" alt="Imagem 2" />
        </div>
        <div className="carousel-img">
          <img src="https://i.postimg.cc/7hPrL1Wp/insta-america.jpg" alt="Imagem 3" />
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
