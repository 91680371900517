import "./newbaseleads.css";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { doc, getDoc, getDocs, updateDoc, collection } from "firebase/firestore";

import { useParams, useRouteMatch } from "react-router-dom";

import { db } from "../../services/firebaseConnection";

import { FiUserPlus } from "react-icons/fi";

export default function NewBaseLeads() {
    const [captador, setCaptador] = useState("");
    const [status, setStatus] = useState("");
    const [nome, setNome] = useState("");
    const [consultor, setConsultor] = useState("");
    const [codigo, setCodigo] = useState("");
    const [email, setEmail] = useState("");
    const [indicacao, setIndicacao] = useState("");
    const [indicacao2, setIndicacao2] = useState("");
    const [indicacao3, setIndicacao3] = useState("");
    const [indicacao4, setIndicacao4] = useState("");
    const [indicacao5, setIndicacao5] = useState("");
    const [transferido, setTransferido] = useState("");
    const [data, setData] = useState("");
    const [dataCriacao, setDataCriacao] = useState("");
    const [dataLembrete, setDataLembrete] = useState("");

    const [complemento, setComplemento] = useState("");

    const listRef = collection(db, "leads");

    const { user } = useContext(AuthContext);
    const match = useRouteMatch("/newbaseleads/:id");
    const id = match && match.params.id;

    const [loadCustomers, setLoadCustomers] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [customerSelected, setCustomerSelected] = useState(0);

    const [idCustomer, setIdCustomer] = useState(false);

    useEffect(() => {
        async function loadCustomers() {
            const querySnapshot = await getDoc(listRef)
                .get()
                .then((snapshot) => {
                    let lista = [];
                    snapshot.forEach((doc) => {
                        lista.push({
                            id: doc.id,
                            codigo: doc.data().codigo,
                            nome: doc.data().nome,
                            data: doc.data().data,
                            consultor: doc.data().consultor,
                            captador: doc.data().captador,
                            status: doc.data().status,
                            indicacao1: doc.data().indicacao1,
                            indicacao2: doc.data().indicacao2,
                            indicacao3: doc.data().indicacao3,
                            indicacao4: doc.data().indicacao4,
                            indicacao5: doc.data().indicacao5,
                            transferido: doc.data().transferido,
                            dataCriacao: doc.data().dataCriacao,
                            dataLembrete: doc.data().dataLembrete,
                        });
                    });



                    if (snapshot.doc.size === 0) {
                        console.log("Nenhum registro encontrado");
                        setCustomers([{ id: "1", nome: "TESTE" }]);
                        setLoadCustomers(false);
                        return;
                    }

                    setCustomers(lista);
                    setLoadCustomers(false);

                    if (id) {
                        loadId(lista);
                    }
                })
                .catch((error) => {
                    console.log("ERROR!!!!", error);
                    setLoadCustomers(false);
                    setCustomers([{ id: "1", nome: "" }]);
                });
        }

        loadCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    async function loadId(lista) {
        const docRef = doc(db, "leads", id);
        await getDoc(docRef)
            .then((snapshot) => {
                setCodigo(snapshot.data().codigo);
                setNome(snapshot.data().nome);
                setData(snapshot.data().data);
                setConsultor(snapshot.data().consultor);
                setCaptador(snapshot.data().captador);
                setStatus(snapshot.data().status);
                setIndicacao(snapshot.data().indicacao);
                setIndicacao2(snapshot.data().indicacao2);
                setIndicacao3(snapshot.data().indicacao3);
                setIndicacao4(snapshot.data().indicacao4);
                setIndicacao5(snapshot.data().indicacao5);
                setTransferido(snapshot.data().transferido);
                setDataCriacao(snapshot.data().dataCriacao);
                setDataLembrete(snapshot.data().dataLembrete);

                let index = lista.findIndex(
                    (item) => item.id === snapshot.data().nomeId
                );
                setCustomerSelected(index);
                setIdCustomer(true);
            })
            .catch((error) => {
                // console.log(error)
                setIdCustomer(false);
            });
    }

    // console.log(filial,setor)

    function handleChangeSelectCaptador(e) {
        setCaptador(e.target.value);
    }

    function handleOptionChangeStatus(e) {
        setStatus(e.target.value);
    }

    function refresh() {
        window.location.reload();
    }

    async function handleRegister(e) {
        e.preventDefault();
        if (id) {
            const docRef = doc(db, "leads", id);
            await updateDoc(docRef, {
                codigo: codigo,
                nome: nome,
                data: data,
                consultor: consultor,
                captador: captador,
                status: status,
                indicacao: indicacao,
                indicacao2: indicacao2,
                indicacao3: indicacao3,
                indicacao4: indicacao4,
                indicacao5: indicacao5,
                transferido: transferido,
                dataCriacao: dataCriacao,
                dataLembrete: dataLembrete,
            })
                .then(() => {
                    toast.info("Registro atualizado com sucesso!");
                    setCustomerSelected(0);
                    setComplemento("");


                })
                .catch((error) => {
                    toast.error("Ops erro ao atualizar!");
                    console.log(error);
                });

            return;
        }

        await firebase
            .firestore()
            .collection("leads")
            .add({
                create: new Date(),
                codigo: codigo,
                nome: nome,
                data: data,
                consultor: consultor,
                captador: captador,
                status: status,
                indicacao: indicacao,
                indicacao2: indicacao2,
                indicacao3: indicacao3,
                indicacao4: indicacao4,
                indicacao5: indicacao5,
                transferido: transferido,
                dataCriacao: dataCriacao,
                dataLembrete: dataLembrete,
                userId: user.uid,
            })
            .then(() => {
                toast.success("Registro cadastrado com sucesso!");
                setCodigo("");
                setNome("");
                setData("");
                setConsultor("");
                setCaptador("");
                setStatus("");
                setIndicacao("");
                setIndicacao2("");
                setIndicacao3("");
                setIndicacao4("");
                setIndicacao5("");
                setTransferido("");
                setDataCriacao("");
            })
            .catch((err) => {
                toast.error("Erro ao registrar, tente mais tarde.");
                console.log(err);
            });
    }

    useEffect(() => {
        loadId();
    }, [id]);



    return (
        <div>
            <Header />
            <div className="content">
                <Title name={id ? "Editando base de leads" : "Novo registro"}>
                    <FiUserPlus size={25} />
                </Title>

                {/* <button type='submit' onClick={loadId}>Edit</button> */}
                <div className="formulario">
                    <form className="form-register" onSubmit={handleRegister}>

                        <label>Código:</label>
                        <input
                            type="text"
                            placeholder="Código"
                            value={codigo}
                            onChange={(e) => setCodigo(e.target.value)}
                        ></input>


                        <label>Nome do cliente:</label>
                        <input
                            type="text"
                            placeholder="Nome do cliente"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        ></input>

                        <label>Data:</label>
                        <input
                            type="date"
                            value={data}
                            onChange={(e) => setData(e.target.value)}
                        ></input>

                        <label>Consultor:</label>
                        <input
                            type="text"
                            placeholder="Nome do consultor"
                            value={consultor}
                            onChange={(e) => setConsultor(e.target.value)}
                        ></input>

                        <label>Captador:</label>
                        <select
                            className="filial-label"
                            onChange={handleChangeSelectCaptador}
                            value={captador}
                        >
                            <option value="">Selecione o captador</option>
                            <option value="Carla Cristina">
                                Carla Cristina
                            </option>
                            <option value="Isadora Mendonça">
                                Isadora Mendonça
                            </option>
                            <option value="Leticia Paiva">
                                Leticia Paiva
                            </option>
                            <option value="Maykon Alves">
                                Maykon Alves
                            </option>
                            <option value="Priscilla Tomaz">
                                Priscilla Tomaz
                            </option>
                            <option value="Taiane Silva">
                                Taiane Silva
                            </option>

                        </select>

                        <label>Status:</label>
                        <select
                            className="setor"
                            onChange={handleOptionChangeStatus}
                            value={status}
                        >
                            <option value="">Selecione o status</option>
                            <option value="Atendido/wpp">Atendido/wpp</option>
                            <option value="Contrato">Contrato</option>
                            <option value="Em tratativa">Em tratativa</option>
                            <option value="Estrangeiro">Estrangeiro</option>
                            <option value="Indicou">Indicou</option>
                            <option value="Já possui visto">Já possui visto</option>
                            <option value="Sem interesse">Sem interesse</option>
                            <option value="Sem resposta">Sem resposta</option>
                            <option value="Wpp">Wpp</option>
                        </select>

                        <label>Indicação 1:</label>
                        <input
                            type="text"
                            placeholder="Indicação 1"
                            value={indicacao}
                            onChange={(e) => setIndicacao(e.target.value)}
                        ></input>

                        <label>Indicação 2:</label>
                        <input
                            type="text"
                            placeholder="Indicação 2"
                            value={indicacao2}
                            onChange={(e) => setIndicacao2(e.target.value)}
                        ></input>

                        <label>Indicação 3:</label>
                        <input
                            type="text"
                            placeholder="Indicação 3"
                            value={indicacao3}
                            onChange={(e) => setIndicacao3(e.target.value)}
                        ></input>

                        <label>Indicação 4:</label>
                        <input
                            type="text"
                            placeholder="Indicação 4"
                            value={indicacao4}
                            onChange={(e) => setIndicacao4(e.target.value)}
                        ></input>

                        <label>Indicação 5:</label>
                        <input
                            type="text"
                            placeholder="Indicação 5"
                            value={indicacao5}
                            onChange={(e) => setIndicacao5(e.target.value)}
                        ></input>

                        <label>Transferido:</label>
                        <input
                            type="text"
                            placeholder="Transferido"
                            value={transferido}
                            onChange={(e) => setTransferido(e.target.value)}
                        ></input>

                        <label>Data de criação:</label>
                        <input
                            type="date"
                            value={dataCriacao}
                            onChange={(e) => setDataCriacao(e.target.value)}
                        ></input>

                        <label>Lembrete:</label>
                        <input
                            type="date"
                            value={dataLembrete}
                            onChange={(e) => setDataCriacao(e.target.value)}
                        ></input>



                    </form>
                    <button className="register-btn" type="submit" onClick={handleRegister}>
                        Registrar
                    </button>
                    <Link className="button-link-baseleads" to="/baseleads">
                        Voltar
                    </Link>

                </div>
            </div>
        </div>
    );
}
