import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { FiX } from 'react-icons/fi';



//




export default function ModalSac({conteudo,close}){
    const { user, signOut } = useContext(AuthContext);
    return(
        <div className='modal-feedback'>
            <div className='container-feed'>
                <button className='close' onClick={close}>
                    <FiX size={23} color="#FFF"/>
                    
                </button>
                
                <div className="detalhes-feedback">
                    <h2>Observação do cliente</h2>

                    <div className='row'>
                        <span>
                            
                        </span>
                    </div>
                    <div className='row'>
                        <span>
                            <textarea className="area-obs">{conteudo.observacao}</textarea>
                        </span>
                    </div>

                    
                </div>
                
            </div>
        </div>
    )
}