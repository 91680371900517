import "./clock.css";
import React, { useState, useEffect } from "react";

function Clock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const options = {
    timeZone: "America/New_York",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  const formattedTime = time.toLocaleString("en-US", options);

  return (
    <div className="clock">
      <p>Hora atual nos USA: {formattedTime}</p>
    </div>
  );
}

export default Clock;
