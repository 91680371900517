import { useState, useContext } from 'react';

import { AuthContext } from '../../contexts/auth';

import './signin.css';
import logo from '../../assets/logoamerica.png'

import { FiMail, FiLock } from "react-icons/fi";

import Carousel from '../../components/Carousel';

import firebase from "../../services/firebaseConnection";
import "firebase/firestore";
import { toast } from 'react-toastify';

import { Link } from "react-router-dom";

function SingIn() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {signIn,loadingAuth} = useContext(AuthContext);



  function recoverPassword(){

    firebase.auth().sendPasswordResetEmail(email)
    .then(()=>{
      toast.success("Email enviado com sucesso!");
    }).catch((error)=>{
      toast.error("Digite seu email no login!")
    });
  }



  function handleSubmit (e) {
    e.preventDefault();
    if(email !== '' && password !==''){
      signIn(email, password)
    }
  }



    return (
      <div className="container-login" >
        
        <div className="container-carousel">
          <Carousel/>
        </div>
        
        <div className="login-area">
          <div className="login-border">
              <div className="logo">
                <img src={logo} alt="Sistema Logo"/>
              </div>
              
            <form className="form-signin" onSubmit={handleSubmit}>
              <h1>Olá Colaborador !</h1>
              <h4>Adicione seus dados para acessar nossa pagina de colaboradores.</h4>
              <div className="login-mail">
              <input type="text" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value) } /> 
              <FiMail  size={20}/>
              </div>
              <div className="login-password">
              <input type="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value) }/>
              <FiLock  size={20}/>
              </div>
              <button className="btn-login" type="submit">{loadingAuth ? 'Carregando...' : 'Entrar'}</button>
              
            </form>
            <Link className='recoveryPassword' to="/ResetPassword">Esqueceu sua senha ?</Link>
          </div>
          


          <footer>
             © Copyright 2023   |   GRUPO AMÉRICA  |   Todos os direitos reservados.
          </footer>
        </div>
          
          
     
        
      </div>
      
    )
    //(
    //   // <div className='container'>
    //   //   <div className='login'>
    //   //     <div className='logo'>
    //   //       <img src={logo} alt="Sistema Logo" />
    //   //     </div>

    //   //     <form onSubmit={handleSubmit}>
    //   //       <h1>Entrar</h1>
    //   //       <input type="text" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value) } /> 
    //   //       <input type="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value) }/>
    //   //       <button type="submit">{loadingAuth ? 'Carregando...' : 'Acessar'}</button>
    //   //     </form>
    //   //   </div>
    //   //   <div className="warning">

    //   //   </div>
    //   //   <footer>
    //   //   © Copyright 2023   |   GRUPO AMÉRICA  |   Todos os direitos reservados.   |   Desenvolvido por América Tecnologia
    //   //   </footer>
    //   // </div>
    // );
  }
  
  export default SingIn;
  
