import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bradesco from '../../assets/bradesco.mp4';


import './carouselDp.css';

const CarouselDp = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    adaptiveHeight: true
    
  };

  return (
    <div className="carousel-dp">
      <Slider {...settings}>
        
        <div className="carouseldp-img">
          <img  className="carousel-img" src="https://i.postimg.cc/7hPrL1Wp/insta-america.jpg" alt="Imagem 2"  />
        </div>
        <div className="carouseldp-img">
          <img src="https://i.postimg.cc/7hPrL1Wp/insta-america.jpg" alt="Imagem 3" />
        </div> 
        <div className="carouseldp-video">
          <h2>Vídeo explicativo de como abrir Conta corrente-Salário</h2>
          <video width="520" height="480" controls src={bradesco}>
          </video>
        </div> 
      </Slider>
    </div>
  );
};

export default CarouselDp;
