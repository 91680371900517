import Header from '../../components/Header';
import Title from "../../components/Title";

import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";
import firebase from "../../services/firebaseConnection";


import { doc, getDoc, updateDoc, collection } from "firebase/firestore";

import { useHistory, useParams, Link } from "react-router-dom";

import { db } from "../../services/firebaseConnection";

import { toast } from "react-toastify";

import { FiMonitor } from "react-icons/fi";

import "./newFeedback.css";

function Monitoring() {
  const [responsavel, setResponsavel] = useState('');
  const [dataAnalise, setDataAnalise] = useState('');
  const [nomeCliente, setNomeCliente] = useState('');
  const [numeroCliente, setNumeroCliente] = useState('');
  const [observacoes, setObservacoes] = useState('');
  const [avaliacao, setAvaliacao] = useState('');
  const [consultor, setConsultor] = useState('');
  const [filial, setFilial] = useState('');



  const [complemento, setComplemento] = useState("");

  const listRef = collection(db, "feedbacks");

  const { user } = useContext(AuthContext);
  const { id } = useParams();

  const [loadCustomers, setLoadCustomers] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState(0);

  const [idCustomer, setIdCustumer] = useState(false);

  useEffect(() => {
    async function loadCustomers() {
      const querySnapshot = await getDoc(listRef)
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              responsavel: doc.data().responsavel,
              consultor: doc.data().consultor,
              dataAnalise: doc.data().dataAnalise,
              nomeCliente: doc.data().nomeCliente,
              numeroCliente: doc.data().numeroCliente,
              filial: doc.data().filial,
              avaliacao: doc.data().avaliacao,
              observacoes: doc.data().observacoes,
            });
          });

          if (snapshot.doc.size === 0) {
            console.log("Nenhum feedback encontrado");
            setCustomers([{ id: "1", nome: "TESTE" }]);
            setLoadCustomers(false);
            return;
          }

          setCustomers(lista);
          setLoadCustomers(false);

          if (id) {
            loadId(lista);
          }
        })
        .catch((error) => {
          console.log("ERROR!!!!", error);
          setLoadCustomers(false);
          setCustomers([{ id: "1", nome: "" }]);

        });

    }

    loadCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function loadId(lista) {
    const docRef = doc(db, "feedbacks", id);
    await getDoc(docRef)
      .then((snapshot) => {
        setResponsavel(snapshot.data().responsavel);
        setDataAnalise(snapshot.data().dataAnalise);
        setFilial(snapshot.data().filial);
        setNomeCliente(snapshot.data().nomeCliente);
        setNumeroCliente(snapshot.data().numeroCliente);
        setObservacoes(snapshot.data().observacoes);
        setConsultor(snapshot.data().consultor);
        setAvaliacao(snapshot.data().avaliacao);

        let index = lista.findIndex(
          (item) => item.id === snapshot.data().consultor.id
        );
        setCustomerSelected(index);
        setIdCustumer(true);
      })
      .catch((error) => {
        // console.log(error)
        setIdCustumer(false);
      });
  }

  // console.log(filial,setor)

  function limparFormulario() {
        setResponsavel('');
        setDataAnalise('');
        setFilial('');
        setNomeCliente('');
        setNumeroCliente('');
        setObservacoes('');
        setConsultor('');
        setAvaliacao('');
  }

  function handleSubmit(event) {
    event.preventDefault();
    handleRegister();
  }

  function handleOptionChangeResp(e) {
    setResponsavel(e.target.value);
  }

  function handleOptionChangeResp(e) {
    setResponsavel(e.target.value);
  }
  function handleOptionChange(e) {
    setConsultor(e.target.value);
  }
  
  function handleRefresh() {
    window.location.reload();
  }

  async function handleRegister(e) {
    e.preventDefault();
    if (id) {
      const docRef = doc(db, "feedbacks", id);
      await updateDoc(docRef, {
        responsavel: responsavel,
        dataAnalise: dataAnalise,
        filial: filial,
        nomeCliente: nomeCliente,
        numeroCliente: numeroCliente,
        observacoes: observacoes,
        consultor: consultor,
        avaliacao: avaliacao,

      })
        .then(() => {
          toast.info("Feedback atualizado com sucesso!");
          setCustomerSelected(0);
          setComplemento("");
        })
        .catch((error) => {
          toast.error("Ops erro ao atualizar esse feedback!");
          console.log(error);
        });

      return;
    }

    await firebase
      .firestore()
      .collection("feedbacks")
      .add({
        create: new Date(),
        responsavel: responsavel,
        dataAnalise: dataAnalise,
        filial: filial,
        nomeCliente: nomeCliente,
        numeroCliente: numeroCliente,
        avaliacao: avaliacao,
        consultor: consultor,
        observacoes: observacoes,
      })
      .then(() => {
        toast.success("Feedback registrado com sucesso!");
      })
      .catch((err) => {
        toast.error("Erro ao registrar, tente mais tarde.");
        console.log(err);
      });
      limparFormulario();
  }

  //console.log(loadId)
  useEffect(() => {
    loadId();
  }, [id]);


  return (
    <div className="container-monitoring">
      <Header />
      <div className="content">
        <Title name="Novo Feedback">
          <FiMonitor size={25} />
        </Title>

        <div className="formulario-monitoramento">
          <div className="titulo-feedback">
            <h2>Formulario de Feedback</h2>
          </div>
          <form className="form-monitoring"
            onSubmit={handleRegister}>

        
            <div className="respon-registro">
              Responsável pelo registro:
              <select
                className="responsavel-registro"
                value={responsavel}
                onChange={handleOptionChangeResp}
                required
              >
                <option value="">Selecione o responsável pelo registro</option>
                <option value="Ana Luiza Drumond">Ana Luiza Drumond</option>
                <option value="Isadora Mendonça">
                  Isadora Mendonça
                </option>
                <option value="Rafaele Sedrez">
                  Rafaele Sedrez
                </option>
                <option value="Rafaela Marques">
                  Rafaela Marquês
                </option>
              </select>
            </div>

            <div className="consultor-select">
              Consultor:
              <select
                className="consultor"
                value={consultor}
                onChange={handleOptionChange}
                required
              >
                <option value="">Selecione o consultor</option>
                <option value="Adriana Lima">Adriana Lima</option>
                <option value="Ákyla Lopes">Ákyla Lopes</option>
                <option value="Amanda Silva">Amanda Silva</option>
                <option value="Alcimare da Consolação">Alcimare da Consolação</option>
                <option value="Ana Luiza Frossard">Ana Luiza Frossard</option>
                <option value="Bianca de Paula">Bianca de Paula</option>
                <option value="Bianca Miller">Bianca Miller</option>
                <option value="Carla Cristina">Carla Cristina</option>
                <option value="Dênia de Paula">Dênia de Paula</option>
                <option value="Eliz Ladeia">Eliz Ladeia</option>
                <option value="Fabiana da Silva">Fabiana da Silva</option>
                <option value="Felipe Viega">Felipe Viega</option>
                <option value="Francislaine Silva">Francislaine Silva</option>
                <option value="Gabrielly de Assis">Gabrielly de Assis</option>
                <option value="Guilherme Miranda">Guilherme Miranda</option>
                <option value="Guizilla Somerlate">Guizilla Somerlate</option>
                <option value="Igor Silveira">Igor Silveira</option>
                <option value="Isabella Martins">Isabella Martins</option>
                <option value="Izabella Gonçalves">Izabella Gonçalves</option>
                <option value="Jackcimara Pereira">Jackcimara Pereira</option>
                <option value="Julia Carvalho">Julia Carvalho</option>
                <option value="Katiuse Lima">Katiuse Lima</option>
                <option value="Késsia Rodrigues">Késsia Rodrigues</option>
                <option value="Kewlen Victoria">Kewlen Victoria</option>
                <option value="Larissa Ferreira">Larissa Ferreira</option>
                <option value="Leonardo Guilherme">Leonardo Guilherme</option>
                <option value="Lidiane Imaculada">Lidiane Imaculada</option>
                <option value="Logan Lins">Logan Lins</option>
                <option value="Lorraine Alves">Lorraine Alves</option>
                <option value="Luana Marçal">Luana Marçal</option>
                <option value="Lucilene Bento">Lucilene Bento</option>
                <option value="Marcela Sabriny">Marcela Sabriny</option>
                <option value="Maria Julia">Maria Julia</option>
                <option value="Mariana de Paula">Mariana de Paula</option>
                <option value="Maykon Alves">Maykon Alves</option>
                <option value="Michele Lima">Michele Lima</option>
                <option value="Nathalia Sthefani">Nathalia Sthefani</option>
                <option value="Nicolas da Silva">Nicolas da Silva</option>
                <option value="Raphael Pereira">Raphael Pereira</option>
                <option value="Taiane Silva">Taiane Silva</option>
                <option value="Taislaine Ketly">Taislaine Ketly</option>
                <option value="Talita Raiane">Talita Raiane</option>
                <option value="Thais Caroline">Thais Caroline</option>
                <option value="Thalysson Roberto">Thalysson Roberto</option>
                <option value="Vicente Camilo">Vicente Camilo</option>
                <option value="Walter Carneiro">Walter Carneiro</option>
                <option value="Wanderson Cordeiro">Wanderson Cordeiro</option> 
                <option value="Williane Lopes">Williane Lopes</option>
              </select>
            </div>

            <div className="data-analise">
              Data de análise:
              <input type="date" value={dataAnalise} onChange={(e) => setDataAnalise(e.target.value)} required />
            </div>
            <div className="nome-cliente">
              Nome do cliente:
              <input type="text" value={nomeCliente} onChange={(e) => setNomeCliente(e.target.value)} required />
            </div>
            <div className="numero-cliente">
              Número do cliente:
              <input type="text" value={numeroCliente} onChange={(e) => setNumeroCliente(e.target.value)} required />
            </div>

            <div className="filial-select">
              Filial:
              <select
                className="filial-label"
                value={filial}
                onChange={(e) => setFilial(e.target.value)}
                required
              >
                <option value="">Selecione a sua Filial</option>
                <option value="Matriz">Matriz</option>
                <option value="Filial 01 (Cidade Nobre - Ipatinga-MG)">
                  Filial 01 (Cidade Nobre - Ipatinga-MG)
                </option>
                <option value="Filial 02 (Centro - Ipatinga-MG)">
                  Filial 02 (Centro - Ipatinga-MG)
                </option>
                <option value="Filial 03 (Shopping Vale do Aço - Ipatinga-MG)">
                  Filial 03 (Shopping Vale do Aço - Ipatinga-MG)
                </option>
                <option value="Filial 04 (Ponteio Lar Shopping - BH)">
                  Filial 04 (Ponteio Lar Shopping - BH)
                </option>
                <option value="Filial 05 (Caratinga-MG)">
                  Filial 05 (Caratinga-MG){" "}
                </option>
                <option value="Filial 06 (Iguaçu - Ipatinga-MG)">
                  Filial 06 (Iguaçu - Ipatinga-MG)
                </option>
                <option value="Manhuaçu">Manhuaçu</option>
              </select>
            </div>

            <div className="feedback">
              <div className="avaliacao-atendimento"
                value={avaliacao}
                onChange={(e) => setAvaliacao(e.target.value)}
                required
              >
                Avaliação do atendimento:

               <fieldset required>
                <label>
                  <input type="radio" name="opcao" value="A" required />
                  Padrão
                </label>
                <label>
                  <input type="radio" name="opcao" value="B" required />
                  Regular
                </label>
                <label>
                  <input type="radio" name="opcao" value="C" required />
                  Ruim
                </label>
                </fieldset>
              </div>
            </div>

            <div className="obs-atendimento">
              Observações do atendimento:
              <textarea value={observacoes} onChange={(e) => setObservacoes(e.target.value)} required/>

              <div className="btn-feedback">
              <button className="btn-registrar" type="submit" >Registrar</button>
              <Link className="btn-registrar-link" to="/baseleads">
                        Voltar
                    </Link>
            </div>

            </div>




           
          </form>
        </div>

      </div>

    </div>

  );
}

export default Monitoring;
