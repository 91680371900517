

import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import axios from 'axios';

import './chart.css'

class DollarCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    axios.get('https://www.alphavantage.co/query?function=FX_DAILY&from_symbol=USD&to_symbol=BRL&apikey=IPQH2J4PDAW3ZBI6')
      .then(response => {
        const data = response.data['Time Series FX (Daily)'];
        const chartData = [['Data', 'Valor']];
        for (let key in data) {
          chartData.push([key, parseFloat(data[key]['4. close'])]);
        }
        this.setState({ data: chartData });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="container-chart">
        <Chart
          chartType="LineChart"
          data={this.state.data}
          options={{
            title: 'Valor do Dólar',
            legend: { position: 'bottom' },
            backgroundColor: ('#var(--section-overlay)'),
            chartArea: { width: '80%', height: '70%' },
            colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
            
          }}
        />
      </div>
    );
  }
}

export default DollarCharts;