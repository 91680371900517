import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import Header from "../../components/Header";
import Title from "../../components/Title";

import { FiUserPlus } from "react-icons/fi";
import logo from "../../assets/logoamerica.png";

import "./signup.css";
function SingUp() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  const [cupom, setCupom] = useState("");

  const { signUp, loadingAuth } = useContext(AuthContext);

  function handleSubmit(e) {
    e.preventDefault();

    if (nome !== "" && email !== "" && password !== "" && type!=="" && date!=="" && cupom!=="") {
      signUp(email, password, nome, type, date, cupom);
    }
  }

  return (
    <div className="register-user">
      <Header />
      <div className="content">
        <Title name="Cadastrar Usuário">
          <FiUserPlus size={20} />
        </Title>
      </div>
      <div className="container-border">
        <div className="container-register">
        <div className='register-colab'>
          
              <img src={logo} alt="Sistema Logo" />
            </div>

          <div className="formulario">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <input
              type="text"
              placeholder="email@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="***********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

          <input
              type="date"
              placeholder="***********"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />

            <select
              className="setor"
              onChange={(e)=>setType(e.target.value)}
              value={type}
            >
              <option value="">Selecione o seu Setor</option>
              <option value="Administrativo">Administrativo</option>
              <option value="Cadastro">Cadastro</option>
              <option value="Comercial">Comercial</option>
              <option value="Diretoria">Diretoria</option>
              <option value="Departamento Pessoal">Departamento Pessoal</option>
              <option value="Financeiro">Financeiro</option>
              <option value="Gerenciamento de Leads">
                Gerenciamento de Leads
              </option>
              <option value="Gestão comercial">
                Gestão comercial
              </option>
              <option value="Jurídico">Jurídico</option>
              <option value="Marketing">Marketing</option>
              <option value="SAC">SAC</option>
              <option value="Segurança">Segurança</option>
              <option value="TI">TI</option>
              <option value="Tranmissão">Tranmissão</option>
              <option value="Treinamento">Treinamento</option>
              <option value="Turismo">Turismo</option>
            </select>

            <input
              type="text"
              placeholder="*Cupom de desconto*"
              value={cupom}
              onChange={(e) => setCupom(e.target.value)}
            />

            <button className="btn-cadastrar" type="submit">
              "Cadastrar"
            </button>
          </form>
          </div>
          </div>
        </div>
    </div>
  );
}

export default SingUp;
