import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import moment from 'moment';

import { toast } from "react-toastify";
import Header from "../../components/Header";
import Title from "../../components/Title";
import {
  FiList,
  FiPlus,
  FiClipboard,
  FiSearch,
  FiTrash2,
  FiUsers,
  FiEdit2
} from "react-icons/fi";

import { SiMicrosoftexcel } from "react-icons/si"
import { CgPlayListSearch } from "react-icons/cg";
import { Link } from "react-router-dom";

import firebase from "../../services/firebaseConnection";
import "firebase/firestore";

import * as XLSX from 'xlsx';

import ModalSac from "../../components/ModalSac";


const listRef = firebase.firestore().collection("SacForm");

export default function SacReport() {
  const [func, setFunc] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();
  const [todosForms, setTodosFeedbacks] = useState([]);

  const [filialSearch, setFilialSearch] = useState("");
  const [dateSearch, setDateSearch] = useState('');

  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  // console.log(search);
  const searchLowerCase = search.toLowerCase();
  const db = firebase.firestore();
  const [searchTerm, setSearchTerm] = useState("");

  const displayedFuncs = func
    .filter((item) => {
      const searchLowerCase = searchTerm.toLowerCase();
      return (
        item.nomeCliente.toLowerCase().includes(searchLowerCase) ||
        item.contrato.toLowerCase().includes(searchLowerCase) ||
        item.telefone.toLowerCase().includes(searchLowerCase) ||
        item.dataSolicitacao.toLowerCase().includes(searchLowerCase) ||
        item.motivo.toLowerCase().includes(searchLowerCase)
      );
    });

  function searchDate() {
    const searchLowerCase = search.toLowerCase();
    const userSearch = listRef.filter(
      (consultor) =>
        consultor.dataAnalise.toLowerCase().includes(searchLowerCase)
    );
    setFunc(userSearch);
  }

  // function searchFiliais() {
  //   const searchLowerCase = filialSearch.toLowerCase();
  //   const filiaisSearch = func.filter(
  //     (consultor) =>
  //     consultor.filial.toLowerCase().includes(searchLowerCase) 
  //   );
  //   setFunc(filiaisSearch);
  // }
  function deleteFeedback(id) {
    const confirmDelete = window.confirm("Deseja mesmo excluir o formulário?");
    if (confirmDelete) {
      const userDelete = func.filter((feedback) => feedback.id !== id);
      setFunc(userDelete);

      db.collection("SacForm")
        .doc(id)
        .delete()
        .then(() => {
          toast.success("Formulário deletado com sucesso!");
        })
        .catch(() => {
          toast.error("Erro ao deletar");
        });
    } else {
      return;
    }
  }




  const exportData = async () => {
    const worksheet = XLSX.utils.json_to_sheet(func); // converte os dados em uma planilha Excel
    const workbook = XLSX.utils.book_new(); // cria um novo livro de Excel
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados'); // adiciona a planilha de dados ao livro de Excel
    XLSX.writeFile(workbook, 'feedbacks.xlsx'); // baixa o arquivo Excel com os dados

  };



  useEffect(() => {
    async function loadForm() {
      await listRef
        .limit()
        .get()
        .then((snapshot) => {
          updateState(snapshot);
        })
        .catch((err) => {
          console.log("Erro ao buscar", err);
          setLoadingMore(false);
        });

      setLoading(false);
    }

    loadForm();

    return () => { };
  }, []);

  useEffect(() => {
    if (search === "") {
      setFunc(todosForms);
    }
  }, [search]);

  async function updateState(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];

      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          nomeCliente: doc.data().nomeCliente,
          contrato: doc.data().contrato,
          telefone: doc.data().telefone,
          dataSolicitacao: doc.data().dataSolicitacao,
          motivo: doc.data().motivo,
          observacao: doc.data().observacao,
          solicitante: doc.data().solicitante,
        });
      });

      const lastDoc = snapshot.docs[snapshot.docs.length - 1]; // pegando o ultimo documento buscado

      setFunc(lista);
      setTodosFeedbacks(lista);
      setLastDocs(lastDocs);
    } else {
      setIsEmpty(true);
    }

    setLoadingMore(false);
  }

  async function handleMore() {
    setLoadingMore(true);
    await listRef
      .limit()
      .get()
      .then((snapshot) => {
        updateState(snapshot);
      });
  }

  function togglePostModal(item) {
    setShowPostModal(!showPostModal); // Trocando de true para false conforme recebe o valor
    setDetail(item);
  }

  const ordenarCrescente = (index) => {
    const ordenado = [...func].sort((a, b) => {
      if (a[index] < b[index]) {

        return -1;

      }
      if (a[index] > b[index]) {
        return 1;
      }
      return 0;
    });
    setFunc(ordenado);
  }

  const ordenarDecrescente = (index) => {
    const ordenado = [...func].sort((a, b) => {
      if (a[index] > b[index]) {

        return -1;

      }
      if (a[index] < b[index]) {
        return 1;
      }
      return 0;
    });
    setFunc(ordenado);
  }

  function handlePageClick(data) {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  }

  if (loading) {
    return (
      <div>
        <Header />
        <div className="content-colab">
          <Title name="Lista">
            <FiList size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando formulários....</span>
          </div>
        </div>
      </div>
    );
  }
  const pageCount = Math.ceil(displayedFuncs.length / itemsPerPage);
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Lista de formulários SAC">
          <FiUsers size={20} />
        </Title>
        <div className="divSearch">
          <input
            type="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Buscar"
          />
          <button
            className="btn-search"
            onClick={() => setCurrentPage(0)} // Reinicia a página para a primeira página ao realizar a pesquisa
          >
            <FiSearch size={12} color="#FFF" />
            Buscar
          </button>

          {/* <select
              className="filial-selected"
              onChange={(e) => setFilialSearch(e.target.value)}
              value={filialSearch}
            >
              <option value="">Selecione a sua Filial</option>
              <option value="Matriz">Matriz</option>
              <option value="Filial 01 (Cidade Nobre - Ipatinga-MG)">
                Filial 01 (Cidade Nobre - Ipatinga-MG)
              </option>
              <option value="Filial 02 (Centro - Ipatinga-MG)">
                Filial 02 (Centro - Ipatinga-MG)
              </option>
              <option value="Filial 03 (Shopping Vale do Aço - Ipatinga-MG)">
                Filial 03 (Shopping Vale do Aço - Ipatinga-MG)
              </option>
              <option value="Filial 04 (Ponteio Lar Shopping - BH)">
                Filial 04 (Ponteio Lar Shopping - BH)
              </option>
              <option value="Filial 05 (Caratinga-MG)">
                Filial 05 (Caratinga-MG){" "}
              </option>
              <option value="Filial 06 (Iguaçu - Ipatinga-MG)">
                Filial 06 (Iguaçu - Ipatinga-MG)
              </option>
              <option value="Manhuaçu">Manhuaçu</option>
            </select> */}



        </div>

        {func.length === 0 ? (
          <div className="container dashboard">
            <span>Nenhum registro de feedback ...</span>


          </div>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th scope="col" onClick={() => ordenarCrescente("nomeCliente")}>Cliente</th>
                  <th scope="col" onClick={() => ordenarCrescente("contrato")}>Contrato</th>
                  <th scope="col" onClick={() => ordenarCrescente("telefone")} >Telefone </th>
                  <th scope="col" onClick={() => ordenarCrescente("dataSolicitacao")}> Data de Solicitação</th>
                  <th scope="col" onClick={() => ordenarCrescente("motivo")}>Motivo</th>
                  <th scope="col" onClick={() => ordenarCrescente("solicitante")}>Solicitante</th>
                  <th scope="col" style={{ display: "none" }} onClick={() => ordenarCrescente("observacao")}>Observação</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <tbody>
                {displayedFuncs
                  .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="Cliente" onClick={() => ordenarDecrescente("nomeCliente")}>{item.nomeCliente}</td>
                        <td data-label="Contrato" onClick={() => ordenarDecrescente("contrato")}>{item.contrato}</td>
                        <td data-label="Numero Cliente" onClick={() => ordenarDecrescente("telefone")}>{item.telefone}</td>
                        <td data-label="Numero Cliente" onClick={() => ordenarDecrescente("telefone")}>{item.dataSolicitacao}</td>


                        <td data-label="Filial" onClick={() => ordenarDecrescente("motivo")}>{item.motivo}</td>
                        <td data-label="Data Analise" onClick={() => ordenarDecrescente("solicitante")}>{item.solicitante}</td>
                        <td data-label="Filial" style={{ display: "none" }} onClick={() => ordenarDecrescente("observacao")}>{item.observacao}</td>
                        <td data-label="#">



                          <button
                            className="action"
                            style={{ backgroundColor: "#0B0B34" }}
                            onClick={() => togglePostModal(item)}
                          >
                            <FiClipboard color="#FFF" size={17} />
                          </button>



                          <Link
                            className="action"
                            style={{ backgroundColor: "#f6a935" }}
                            to={`/SacForm/${item.id}`}
                          >
                            <FiEdit2 color="#FFF" size={17} />
                          </Link>


                          <button
                            className="action"
                            style={{ backgroundColor: "#FF0004" }}
                            onClick={() => deleteFeedback(item.id)}
                          >
                            <FiTrash2 color="#FFF" size={17} />
                          </button>


                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              pageCount={Math.ceil(func.length / itemsPerPage)}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />


            <div className="more">


              <div className="novoColaborador">
                <Link to="/SacForm" className="new">
                  <FiPlus size={15} color="#FFF" />
                  Novo formulário
                </Link>

                {/*<div className="exportExcell">
                  <button className="btn-export" onClick={exportData}><SiMicrosoftexcel size={15} />Exportar</button>
              </div>*/}

              </div>

            </div>
          </>
        )}
      </div>

      {showPostModal && <ModalSac conteudo={detail} close={togglePostModal} />}


    </div>
  );
}
