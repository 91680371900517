import './modalFeedback.css';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { FiX } from 'react-icons/fi';



//




export default function ModalFeedback({conteudo,close}){
    const { user, signOut } = useContext(AuthContext);
    return(
        <div className='modal-feedback'>
            <div className='container-feed'>
                <button className='close' onClick={close}>
                    <FiX size={23} color="#FFF"/>
                    
                </button>
                
                <div className="detalhes-feedback">
                    <h2>Detalhes do Feedback</h2>

                    <div className='row'>
                        <span>
                            <a>{conteudo.responsavel}</a>
                        </span>
                    </div>
                    <div className='row'>
                        <span>
                            Observações: <textarea className="area-obs">{conteudo.observacoes}</textarea>
                        </span>
                    </div>

                    
                </div>
                
            </div>
        </div>
    )
}