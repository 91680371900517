import './teste.css';


import tickets05 from "../../assets/ticket05.png";

export default function Teste(){
    
    return(
        <div>
            <img src={tickets05}/>
            <h1>América</h1>
            <button onClick={window.print}>BAIXAR</button>
        </div>
    )
}