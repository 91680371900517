import {useState, createContext, useEffect} from 'react';
import firebase from '../services/firebaseConnection';
import { toast } from 'react-toastify';

export const AuthContext = createContext ({});

function AuthProvider ({children}){
    const [user, setUser] = useState(null);
    const [loadingAuth, setLoadingAuth] = useState(false);
    const [loading, setLoading] = useState(true);

    

    useEffect(()=>{
        
        function loadStorage(){
            const storageUser = localStorage.getItem('SistemaUser');

            if(storageUser){
                setUser(JSON.parse(storageUser));
                setLoading(false);
            }
    
            setLoading(false);

        }

        loadStorage();
        

    },[])

    //Fazendo login do usuário
    async function signIn (email, password){
        setLoadingAuth(true);

        await firebase.auth().signInWithEmailAndPassword(email, password)
        .then( async(value)=>{
            let uid = value.user.uid;

            const userProfile = await firebase.firestore().collection('users')
            .doc(uid).get();

            let data = {
                uid:uid,
                nome:userProfile.data().nome,
                avatarUrl: userProfile.data().avatarUrl,
                email:value.user.email,
                type:userProfile.data().type,
                date:userProfile.data().date,
                cupom:userProfile.data().cupom
            };

            setUser(data);
            storageUser(data);
            setLoadingAuth(false);
            toast.success('Bem vindo a intranet do Grupo América!');

        })
        .catch((error)=>{
            console.log(error);
            toast.error('Senha incorreta!');
           setLoadingAuth(false); 
        })
    }
    
    //Cadastrando um novo usuário
    async function signUp(email, password, nome, type, date, cupom) {
        setLoadingAuth(true);
        
        try {
            const authResult = await firebase.auth().createUserWithEmailAndPassword(email, password);
            const uid = authResult.user.uid;
    
            await firebase.firestore().collection('users').doc(uid).set({
                avatarUrl: null,
                nome: nome,
                email: email,
                type: type,
                date: date,
                cupom: cupom
            });
    
            let data = {
                uid: uid,
                nome: nome,
                email: authResult.user.email,
                avatarUrl: null,
                type: type
            };
    
            // Não fazemos o login automático aqui
    
            setLoadingAuth(false);
            toast.success('Cadastro realizado!');
    
        } catch (error) {
            console.log(error);
            toast.error('Ops, algo deu errado!');
            setLoadingAuth(false);
        }
    }
    
    

    
    function storageUser(data){
        localStorage.setItem('SistemaUser',JSON.stringify(data));
    }

    async function signOut(){
        await firebase.auth().signOut();
        localStorage.removeItem('SistemaUser');
        setUser(null);
    }


    return(
        <AuthContext.Provider value={{
            signed: !!user,
            user,
            loading,
            signUp,
            signOut,
            signIn,
            loadingAuth,
            setUser,
            storageUser
         }}
            >
            {children}
        </AuthContext.Provider>
    )
    }
export default AuthProvider;
