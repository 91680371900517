import React, { useState, useEffect } from "react";
import "./tickets.css";
import Header from '../../components/Header';
import Title from '../../components/Title';

import { HiOutlineTicket } from 'react-icons/hi';

import tickets01 from "../../assets/ticket01.png";
import tickets02 from "../../assets/ticket02.png";
import tickets03 from "../../assets/ticket03.png";
import tickets04 from "../../assets/ticket04.png";
import tickets05 from "../../assets/ticket05.png";

import Cupom from '../../components/Cupom';
import Cupom1 from '../../components/Cupom1';

import Cupom02 from '../../components/Cupom02';
import Cupom022 from '../../components/Cupom022';


import Cupom03 from '../../components/Cupom03';
import Cupom033 from '../../components/Cupom033';

import Cupom04 from '../../components/Cupom04';
import Cupom044 from '../../components/Cupom044';

import Cupom05 from '../../components/Cupom05';
import Cupom055 from '../../components/Cupom055';

export default function Tickets() {
    const [showPostModal, setShowPostModal] = useState(false);
    const [showPostModal1, setShowPostModal1] = useState(false);

    const [showPostModal02, setShowPostModal02] = useState(false);
    const [showPostModal022, setShowPostModal022] = useState(false);

    const [showPostModal03, setShowPostModal03] = useState(false);
    const [showPostModal033, setShowPostModal033] = useState(false);

    const [showPostModal04, setShowPostModal04] = useState(false);
    const [showPostModal044, setShowPostModal044] = useState(false);

    const [showPostModal05, setShowPostModal05] = useState(false);
    const [showPostModal055, setShowPostModal055] = useState(false);

    const [detail, setDetail] = useState();
    const [detail1, setDetail1] = useState();

    const [detail02, setDetail02] = useState();
    const [detail022, setDetail022] = useState();

    const [detail03, setDetail03] = useState();
    const [detail033, setDetail033] = useState();

    const [detail04, setDetail04] = useState();
    const [detail044, setDetail044] = useState();

    const [detail05, setDetail05] = useState();
    const [detail055, setDetail055] = useState();

    useEffect(() => {
        function handleEscKey(event) {
            if (event.keyCode === 27) {
                // Verifica se a tecla pressionada é "Esc" (código 27)
                setShowPostModal(false);
                setShowPostModal1(false);
                setShowPostModal02(false);
                setShowPostModal022(false);
                setShowPostModal03(false);
                setShowPostModal033(false);
                setShowPostModal04(false);
                setShowPostModal044(false);
                setShowPostModal05(false);
                setShowPostModal055(false);
            }
        }

        document.addEventListener("keydown", handleEscKey);

        return () => {
            document.removeEventListener("keydown", handleEscKey);
        };
    }, []);

    function togglePostModal(item) {
        setShowPostModal(!showPostModal);
        setDetail(item);
    }

    function togglePostModal1(item) {
        setShowPostModal1(!showPostModal1);
        setDetail1(item);
    }

    function togglePostModal02(item) {
        setShowPostModal02(!showPostModal02);
        setDetail02(item);
    }

    function togglePostModal022(item) {
        setShowPostModal022(!showPostModal022);
        setDetail022(item);
    }

    function togglePostModal03(item) {
        setShowPostModal03(!showPostModal03);
        setDetail03(item);
    }

    function togglePostModal033(item) {
        setShowPostModal033(!showPostModal033);
        setDetail033(item);
    }

    function togglePostModal04(item) {
        setShowPostModal04(!showPostModal04);
        setDetail04(item);
    }

    function togglePostModal044(item) {
        setShowPostModal044(!showPostModal044);
        setDetail044(item);
    }

    function togglePostModal05(item) {
        setShowPostModal05(!showPostModal05);
        setDetail05(item);
    }

    function togglePostModal055(item) {
      setShowPostModal055(!showPostModal044);
      setDetail055(item);
  }
    return (
        <div>
            <Header className="nav-bar" />
            <div className="content">
                <Title name="Tickets">
                    <HiOutlineTicket size={25} />
                </Title>

                <div className="cards-container-cupom">
                    <div className="card">
                      <img src={tickets01} alt='ticket 01'/>
                        <h2>Ticket 01</h2>
                        
                        <button onClick={togglePostModal} target="_blank" rel="noreferrer">Stories</button>
                        <button onClick={togglePostModal1} target="_blank" rel="noreferrer">Perfil</button>
                    </div>
                    <div className="card">
                        <img src={tickets02} alt="App América"/>
                        <h2>Ticket 02</h2>
                        
                        <button onClick={togglePostModal02} target="_blank" rel="noreferrer">Stories</button>
                        <button onClick={togglePostModal022} target="_blank" rel="noreferrer">Perfil</button>
                    </div>

                    <div className="card">
                        <img src={tickets03} alt="Escallo" />
                        <div className='tittle'>
                            <h2>Ticket 03</h2>
                            
                        </div>
                        <button onClick={togglePostModal03} target="_blank" rel="noreferrer">Stories</button>
                        <button onClick={togglePostModal033} target="_blank" rel="noreferrer">Perfil</button>
                    </div>

                    <div className="card">
                        <img src={tickets04} alt="Descrição da imagem" />
                        <div className='tittle'>
                            <h2>Ticket 04</h2>
                            
                        </div>
                        <button onClick={togglePostModal04} target="_blank" rel="noreferrer">Stories</button>
                        <button onClick={togglePostModal044} target="_blank" rel="noreferrer">Perfil</button>
                       
                    </div>
                </div>

                <div className="cards-container">
                    <div className="card">
                        <img src={tickets05} alt="OctaDesk" />
                        <h2>Ticket 05</h2>
                        
                        <button onClick={togglePostModal05} target="_blank" rel="noreferrer">Stories</button>
                        <button onClick={togglePostModal055} target="_blank" rel="noreferrer">Perfil</button>
                    </div>
                </div>
            </div>

            {showPostModal && <Cupom conteudo={detail} close={togglePostModal} />}
            {showPostModal1 && <Cupom1 conteudo={detail1} close={togglePostModal1} />}
            {showPostModal02 && <Cupom02 conteudo={detail02} close={togglePostModal02} />}
            {showPostModal022 && <Cupom022 conteudo={detail022} close={togglePostModal022} />}
            {showPostModal03 && <Cupom03 conteudo={detail03} close={togglePostModal03} />}
            {showPostModal033 && <Cupom033 conteudo={detail033} close={togglePostModal033} />}
            {showPostModal04 && <Cupom04 conteudo={detail04} close={togglePostModal04} />}
            {showPostModal044 && <Cupom044 conteudo={detail044} close={togglePostModal044} />}
            {showPostModal05 && <Cupom05 conteudo={detail05} close={togglePostModal05} />}
            {showPostModal055 && <Cupom055 conteudo={detail055} close={togglePostModal055} />}
        </div>
    );
}