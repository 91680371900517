import React, { Component } from 'react';
import axios from 'axios';

class DollarValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dollarValue: null
    };
  }

  componentDidMount() {
    axios.get('https://www.alphavantage.co/query?function=CURRENCY_EXCHANGE_RATE&from_currency=USD&to_currency=JPY&apikey=demo')
      .then(response => {
        
        const dollarValue = response.data['Realtime Currency Exchange Rate']['5. Exchange Rate'];
        const dollarFormatted = parseFloat(dollarValue).toFixed(2);
        this.setState({ dollarFormatted });
      })
      .catch(error => {
        console.log(error);
      });
      
  }

  render() {
    return (
      <div className="dolar-container">
        <h2>Valor do Dólar: R$ {this.state.dollarFormatted}</h2>
      </div>
    );
  }
}

export default DollarValue;
